.order__page__new__item__D {
    .add {
        padding-top: 50px !important;
        padding-bottom: 50px !important;
    }
    // other control
    .control__wrap {
        .cus__btn {
            border-radius: 25px;
            padding: 5px 15px;
            border: 1px solid #959dac;
            cursor: pointer;
            font-size: 18px;
        }
        .cus__r__btn {
            @extend .cus__btn;
            padding: 0px;
            width: 37px;
            height: 37px;
            display: grid !important;
            align-items: center;
            justify-items: center;
        }
    }
}

.order__tables {
    padding-bottom: 30px !important;
    @include resp($xxl) {
        padding-bottom: 40px !important;
    }
    table.table {
        // border-left: 0.3px solid $table__col__border;
        // border-right: 0.3px solid $table__col__border;
        // border-bottom: 0.3px solid $table__col__border;
        // table head
        thead {
            tr {
                background-color: $table__head !important;
                color: #374051;
                line-height: 15px;
                th {
                    font-weight: 500;
                }
            }
        }
        // table row
        tr {
            display: grid;
            grid-template-columns: 2.2fr 1.8fr 1.8fr 1.3fr 1.8fr 2.2fr 2.2fr 1.2fr 1.5fr 0.8fr 2.2fr 1.6fr 2fr;

            // strip color
            &:nth-child(even) {
                background-color: $table__even_cell;
                td.note-td:last-child {
                    background-color: $table__even_cell;
                }
            }
            &:nth-child(odd) {
                background-color: white;
                td.note-td:last-child {
                    background-color: white;
                }
            }
            // grid-template-columns: repeat(13, auto);
            // table head
            td,
            th {
                flex: 1;
                padding: 5px;
                display: flex;
                align-items: center;
                padding-top: 6px;
                padding-bottom: 6px;
                img {
                    width: 30px;
                }
                &:first-child {
                    border-right: 1px solid $table__col__border;
                    color: $table__head_color;
                }
            }
            // table head
            th {
                font-size: 12px;
                @include resp($lg) {
                    font-size: 13.5px;
                }
                @include resp($xl) {
                    padding-left: 8px;
                    padding-right: 8px;
                    text-align: left !important;
                }
                @include resp($xxl) {
                    font-size: 15px;
                }
            }
            // table data
            td {
                font-size: 18px;
                font-weight: 300;
                @include resp($lg) {
                    font-size: 20px;
                }
                @include resp($xxl) {
                    font-size: 22px;
                }
                &:first-child {
                    padding-left: 5px;
                    padding-right: 5px;
                    @include resp($lg) {
                        padding-left: 8px;
                        padding-right: 8px;
                    }
                    @include resp($xl) {
                        padding-left: 11px;
                        padding-right: 11px;
                    }
                    @include resp($xxl) {
                        padding: 10px 20px;
                    }
                }
            }
            // custom search icon
            .search__icon {
                background: #8c8c8d;
                border-radius: 50%;
                width: 17px;
                height: 17px;
                display: grid;
                align-items: center;
                justify-items: center;
                font-size: 8px;
                color: white;
                @include resp($xl) {
                    width: 21px;
                    height: 21px;
                    font-size: 11px;
                }
            }

            // hei icon
            .hei__icon {
                width: 8px;
                @include resp($xl) {
                    width: 9.5px;
                }
                @include resp($xxl) {
                    width: 11px;
                }
                margin: 0;
                margin-top: -3px;
            }

            // icon color
            .fa-minus-circle {
                color: $btn__black-500;
            }
            .fa-check-circle {
                color: $checkColor;
            }
        }
    }
    // table bottom
    .table__bottom {
        .table__bottom__counter {
            background-color: $red_bg;
            width: 160px;
            border: 0.01px solid $red;
            div {
                border-right: 0.01px solid $red;
                width: 50%;
                text-align: center;
                font-weight: 500;
                color: $red;
                padding-top: 4px;
                padding-bottom: 4px;
                font-size: 13px;
                @include resp($lg) {
                    font-size: 14px;
                }
                &:last-child {
                    border-right: 0px;
                }
            }
        }
    }
}

// table header
.table__header {
    width: 350px;
    height: 40px;
    @include resp($lg) {
        height: 50px;
    }
    // table title
    .table__title {
        background-color: $header__bg;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }
    // table btn
    .table__tit__btn {
        a {
            font-size: 15px;
            font-weight: 400;
            text-decoration: underline;
        }
    }
    // table title / table bnt
    .table__title {
        width: 40%;
    }
    .table__tit__btn {
        width: 60%;
        span {
            padding: 4px 20px;
            border-radius: 30px;
        }
    }
    .table__title ,
    .table__tit__btn {
        height: 100%;
        h2 {
            font-size: 20px;
        }
    }
}

// cages table
#cages__table {
    @include resp($xl) {
        margin-top: 40px !important;
    }
}

// #cages__table .container {

// }
$tableContainerList: selector-nest(
    "#cages__table, #nets__table, #rebars__table",
    ".container"
);

#{$tableContainerList} {
    @media only screen and (min-width: $md) and (max-width: $lg) {
        max-width: 100%;
    }
    .unit__sm {
        font-size: 12px;
        color: #9A9A9A;
        margin-left: 3px;
    }
    // image icon
    // heading image
    .img__icon {
        width: 20px;
        @include resp($lg) {
            width: 22px;
        }
        @include resp($xl) {
            width: 26px;
        }
        @include resp($xxl) {
            width: 31px;
        }
    }
}

// ----------------------------- nets -----------------------------------------
#nets__table {
    table.table {
        // table row
        tr {
            grid-template-columns: 1fr 2fr 2fr 7fr 2fr 1.6fr 5fr;
        }
        // table head title
        th,
        td {
            padding-left: 11px;
            @include resp($xl) {
                padding-left: 14px;
            }
            @include resp($xxl) {
                padding-left: 18px;
            }
        }
        // tbody.last {
        //     tr:nth-last-child(-n+2) {
        //         td:nth-last-child(-n+3) {
        //             padding-left: 5px !important;
        //         }
        //     }
        // }
        .two__rows_sec{
            display: grid !important;
            grid-template-rows: 1fr 1fr;
            grid-template-columns: 1fr;
            justify-items: center;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 0px;
            margin: 0px;

            .top__sec{
                width: 100%;
                height: 60%;
                padding-bottom: 6px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-bottom: 1.5px solid #9A9A9A !important;
            }
            .bottom_sec{
                width: 100%;
            }
            .bottom_sec-pl{
                padding-left: 11px;
                @include resp($xl) {
                    padding-left: 14px;
                }
            
                @include resp($xxl) {
                    padding-left: 18px;
                }
            }
            .bottom_sec-pr {
                padding-right: 11px;
                @include resp($xl) {
                    padding-right: 18px;
                    padding-left: 6px;
                }
            
                @include resp($xxl) {
                    padding-right: 20px;
                    padding-left: 6px;
                }
            }

        }
        // hei icon
        .hei__icon {
            width: 14px;
            @include resp($xl) {
                width: 16px;
            }
            @include resp($xxl) {
                width: 17px;
            }
            margin: 0;
            margin-top: -2px;
            //margin-right: 3px;
        }
        .hei__icon__last {
            margin-top: -2px;
            margin-left: 3px;
        }
        // image icon
        .img__icon {
            width: 30px;
            @include resp($lg) {
                width: 35px;
            }
            @include resp($xl) {
                font-size: 36px;
            }
        }
        .text__bold {
            font-family: 'Heebo';
            font-style: normal;
            font-weight: 700;
            font-size: 21px;
            line-height: 31px;
            color: #212529;
        }
        ::-webkit-scrollbar {
            width: 0;
        }

        ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px #d2d4d6;
        }

        ::-webkit-scrollbar-thumb {
            background: 1px solid #d2d4d6;
        }
    }
}

#nets__table {
    table.table.rightToLeft {

        // table head title
        th,
        td {
            padding-right: 11px;
            padding-left: 6px;

            @include resp($xl) {
                padding-right: 18px;
                padding-left: 6px;
            }

            @include resp($xxl) {
                padding-right: 20px;
                padding-left: 6px;
            }
        }

        .two__rows_sec {
            display: grid !important;
            grid-template-rows: 1fr 1fr;
            grid-template-columns: 1fr;
            justify-items: center;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 0px;
            margin: 0px;

            .top__sec {
                width: 100%;
                height: 60%;
                padding-bottom: 6px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-bottom: 1.5px solid #9A9A9A !important;
            }

            .bottom_sec {
                width: 100%;
            }

            .bottom_sec-pl {
                padding-left: 11px;
                @include resp($xl) {
                    padding-left: 14px;
                }
            
                @include resp($xxl) {
                    padding-left: 18px;
                }
            }

            .bottom_sec-pr {
                padding-right: 11px;
                @include resp($xl) {
                    padding-right: 18px;
                    padding-left: 6px;
                }
            
                @include resp($xxl) {
                    padding-right: 20px;
                    padding-left: 6px;
                }
            }
        }    

        // tbody.last {
        //     tr:nth-last-child(-n+2) {
        //         td:nth-last-child(-n+3) {
        //             padding-left: 5px !important;
        //         }
        //     }
        // }
    }

}


// ----------------------------- nets -----------------------------------------
#cages__table {
    table.table {
        .two__rows_sec{
            display: grid !important;
            grid-template-rows: 1fr 1fr;
            grid-template-columns: 1fr;
            justify-items: center;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 0px;
            margin: 0px;

            .top__sec{
                width: 100%;
                height: 60%;
                padding-bottom: 6px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-bottom: 1.5px solid #9A9A9A !important;
            }
            .bottom_sec{
                width: 100%;
            }
            .bottom_sec-pl{
                padding-left: 11px;
                @include resp($xl) {
                    padding-left: 14px;
                }
            
                @include resp($xxl) {
                    padding-left: 18px;
                }
            }
            .bottom_sec-pr {
                padding-right: 11px;
                @include resp($xl) {
                    padding-right: 18px;
                    padding-left: 6px;
                }
            
                @include resp($xxl) {
                    padding-right: 20px;
                    padding-left: 6px;
                }
            }

        }
        ::-webkit-scrollbar {
            width: 0;
        }

        ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px #d2d4d6;
        }

        ::-webkit-scrollbar-thumb {
            background: 1px solid #d2d4d6;
        }
    }
}


#cages__table {
    table.table.rightToLeft {

        // table head title
        th,
        td {
            padding-right: 11px;
            padding-left: 6px;

            @include resp($xl) {
                padding-right: 18px;
                padding-left: 6px;
            }

            @include resp($xxl) {
                padding-right: 20px;
                padding-left: 6px;
            }
        }

        .two__rows_sec {
            display: grid !important;
            grid-template-rows: 1fr 1fr;
            grid-template-columns: 1fr;
            justify-items: center;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 0px;
            margin: 0px;

            .top__sec {
                width: 100%;
                height: 60%;
                padding-bottom: 6px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-bottom: 1.5px solid #9A9A9A !important;
            }

            .bottom_sec {
                width: 100%;
            }

            .bottom_sec-pl {
                padding-left: 11px;
                @include resp($xl) {
                    padding-left: 14px;
                }
            
                @include resp($xxl) {
                    padding-left: 18px;
                }
            }

            .bottom_sec-pr {
                padding-right: 11px;
                @include resp($xl) {
                    padding-right: 18px;
                    padding-left: 6px;
                }
            
                @include resp($xxl) {
                    padding-right: 20px;
                    padding-left: 6px;
                }
            }
        }    

        // tbody.last {
        //     tr:nth-last-child(-n+2) {
        //         td:nth-last-child(-n+3) {
        //             padding-left: 5px !important;
        //         }
        //     }
        // }
    }

}

// rebars table
#rebars__table {
    table.table {
        // image icons
        .img__icon {
            width: 100%;
            height: auto;

            max-height: 100%;
        }
        tr th {
            &:nth-child(3) {
                .img__icon {
                    margin-right: 5px;
                }
            }
        }
        .two__rows_sec{
            display: grid !important;
            grid-template-rows: 1fr 1fr;
            grid-template-columns: 1fr;
            justify-items: center;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 0px;
            margin: 0px;

            .top__sec{
                width: 100%;
                height: 60%;
                padding-bottom: 6px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-bottom: 1.5px solid #9A9A9A !important;
            }
            .bottom_sec{
                width: 100%;
            }
            .bottom_sec-pl{
                padding-left: 11px;
                @include resp($xl) {
                    padding-left: 14px;
                }
            
                @include resp($xxl) {
                    padding-left: 18px;
                }
            }
            .bottom_sec-pr {
                padding-right: 11px;
                @include resp($xl) {
                    padding-right: 18px;
                    padding-left: 6px;
                }
            
                @include resp($xxl) {
                    padding-right: 20px;
                    padding-left: 6px;
                }
            }

        }
        // table head title
        th,
        td {
            padding-left: 8px;
            @include resp($xl) {
                padding-left: 15px;
            }
            @include resp($xxl) {
                padding-left: 18px;
            }
        }
        tbody.last {
            tr:nth-last-child(-n+2) {
                td:nth-last-child(-n+3) {
                    padding-left: 5px !important;
                }
            }
        }
        ::-webkit-scrollbar {
            width: 0px;
        }

        ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px #d2d4d6;
        }

        ::-webkit-scrollbar-thumb {
            background: 1px solid #d2d4d6;
        }
    }
}
#rebars__table {
    table.table.rightToLeft {
        // table head title
        th,
        td {
            padding-right: 8px;
            padding-left: 5px;
            @include resp($xl) {
                padding-right: 15px;
                padding-left: 5px;
            }
            @include resp($xxl) {
                padding-right: 18px;
                padding-left: 5px;
            }
        }
        tbody.last {
            tr:nth-last-child(-n+2) {
                td:nth-last-child(-n+3) {
                    padding-left: 5px !important;
                }
            }
        }
    }

}

// bottom order summery or checkout section ------------------------------------- >
#order__summary {
    .container {
        @include resp ($xl) {
            display: flex;
            justify-content: flex-end;
            padding-left: 0px!important;
            padding-right: 0px!important;
        }
    }
    .w-ml-75 {
        @include resp ($xxl) {
            width: 75%!important;
        }
    }
    // description title
    .descrip__title {
        font-size: 18px;
        font-weight: 700;
        color: #353d4a;
        @include resp($xl) {
            font-size: 19.5px;
        }
        @include resp($xxl) {
            font-size: 22px;
        }
    }
    // bottom sec
    .bottom__sec {
        height: 180px;
        width: 100%;
        margin-top: 25px;
    }
    textarea.bottom__sec {
        border: 0px solid #484848;
        border-radius: 5px;
        padding: 22px;
        font-size: 17px;
        font-weight: 600;
    }
    div.bottom__sec {
        // padding-bottom: 12px;
        display: flex;
        flex-flow: column wrap;
        justify-content: space-between;
        div {
            h4 {
                color: #989898 !important;
                font-size: 18px;
                margin: 0px;
            }
            .green-text {
                color: $green__color !important;
            }
        }
    }

    // order summery container
    .order__summery__container {
        // order summery
        .order__summery {
            background-color: #f0f0f0;
            margin-top: 25px;
            border-radius: 5px;
            @include resp ($xl) {
                margin-top: 0px;
            }
            h1 {
                font-size: 23px;
                margin-bottom: 20px !important;
                font-weight: 700;
                @include resp($xxl) {
                    font-size: 25px;
                }
            }
            // table style
            table {
                width: 100%;
                thead {
                    tr {
                        th {
                            border-top: 1px solid black;
                            border-bottom: 1px solid black;
                            padding-top: 7px;
                            padding-bottom: 7px;
                        }
                    }
                }
                // tbody
                tbody {
                    .bold {
                        font-weight: 900;
                    }
                    tr {
                        td {
                            border: none;
                            padding-top: 5px;
                            padding-bottom: 5px;
                        }
                    }

                    // border top
                    .border-top {
                        border-top: 1px solid black !important;
                    }
                    .border-bottom {
                        border-bottom: 1px solid black !important;
                    }
                }
            }
        }
        // order button
        .btn__list {
            .btn {
                padding: 0;
                padding-top: 6px;
                padding-bottom: 6px;
                width: 125px;
                border-radius: 25px;
                background-color: #374051;
                color: white;
                font-size: 18px;
                font-weight: 600;
                // border: 1.5px solid lighten($color: #000000, $amount: 20);
                @include resp($xl) {
                    font-size: 19px;
                }
                @include resp($xxl) {
                    font-size: 20px;
                }
                &:nth-child(1) {
                    width: 35px;
                    height: 35px;
                    padding: 0px;
                    border-radius: 50%;
                    padding-bottom: 10px;
                    background-color: #374051;
                    // border: 1.5px solid lighten($color: #374051, $amount: 30);
                }
                &:nth-child(2) {
                    background-color: #2bc454;
                }
                &:nth-child(4) {
                    background-color: #959dac;
                }
            }
        }
    }
    // custom collapse button
    .ask__for__bid {
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        .custom_collapse_btn {
            padding: 10px 22px;
            background-color: #484848;
            color: white;
            text-align: center;
            border-radius: 25px;
            font-weight: 600;
        }
    } 
}

// ask collapse container 
#askCollapse {
    padding: 25px;
    // untitle list 
    ul {
        list-style: none;
        padding: 0px;
        padding-top: 10px;
        @include resp (1200px) {
            margin-bottom: 0px!important;
        }
        li {
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            @include resp ($lg) {
                margin-bottom: 6px;
            }
            input {
                margin-right: 10px;
            }
            h6 {
                margin-bottom: 0px;
            }
        }
    }
}

.rebar-options {
    .dropdown-toggle::after {
        display: none !important;
    }
    button {
        padding: 0;
    }
    .dropdown-item {
        display: flex;
        align-items: center;
        column-gap: 15px;
    }
}
