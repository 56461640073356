.toggle-switch {
  position: relative;
  width: 75px;
  display: inline-block;
  text-align: left;
}
.toggle-switch.rightToLeft {
    text-align: right;
}
.checkbox {
  display: none;
}
.label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 20px;
  margin: 0px;
}
.inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.inner.rightToLeft {
  margin-right: -100%;
  margin-left: 0;
}
.inner:before,
.inner:after {
  float: left;
  width: 50%;
  height: 36px;
  padding: 0;
  line-height: 36px;
  color: #fff;
  font-weight: bold;
  box-sizing: border-box;
}
.inner.rightToLeft:before,
.inner.rightToLeft:after {
    float: right;
}
.inner:before {
  content: "";
  padding-left: 10px;
  background-color: #00c851;
  color: #fff;
}
.inner.rightToLeft:before {
  padding-right: 10px;
  padding-left: 0;
}
.inner:after {
  content: "";
  padding-right: 10px;
  background-color: #bbb;
  color: #fff;
  text-align: right;
}
.inner.rightToLeft:after {
    padding-left: 10px;
    padding-right: 0;
    text-align: left;
}
.switch {
  display: block;
  width: 24px;
  margin: 5px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 40px;
  border: 0 solid #bbb;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
  height: 24px;
}
.switch.rightToLeft {
    right: 0;
    left: 40px;
}
.checkbox:checked + .label .inner {
  margin-left: 0;
}
.checkbox:checked + .label .switch {
  right: 0px;
}

.checkbox.rightToLeft:checked + .label .inner {
  margin-right: 0;
}
.checkbox.rightToLeft:checked + .label .switch {
  left: 0px;
  right: 40px;
}
