
.csv-btn-container{
  display: flex;
  height: 30%;
  align-items: center;
}

.csv-btn{
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img{
    width: 18px;
    height: 18px;
    margin-right: 4px;
  }

  .spin-size{
    width: 20px;
    height: 20px;
    border: 2px solid #000;
    border-right-color: transparent;
    margin-left: 4px;
  }
}