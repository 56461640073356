
// colors
$main__text: #353d4a;
$dark__500: #383b3e;
$dark: #262729;
$border: #101011;
// form colors 
$formBg: #686868;
$header__bg: #374051;
$formInputbg: #F0F0F0;
$label: #707070;
$btn__black-500: #959DAC;
$btn__black: #374051;
$checkColor: #6EDB93;
$green__color: #2BC454;
$darkgreen__color: #00B38F;
$tools: #F0F0F0;
$red: #F84343;
$red_bg: #FDF4F4;
$h__color: #4E5055;
$light_bg: #FCFCFC;
// table colors
$table__head: #E5E6EA;
$table__col__border: #F0F0F0;
$table__even_cell: #F5F6F8;
$table__head_color: #353D4A;

$filter_circle: #989898;

// tool table colors
$tool_tbl_head: #E5E6EA;
$tool_tbl_head_units: #F5F6F8;
$tool_tbl_head_border: #353D4A;
$tool_tbl_body_border: #F0F0F0;