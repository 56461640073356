.printer__icons{
  display: flex;
  justify-content: space-around;
  align-items: center;

  .printer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    p{
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 22px;

      margin: 10px 0px 5px 0px;
    }

    .select__text{
      color: #374051;    
    }
    .normal__text{    
      color: #AFB3B9;
    }
  }
}

.hr-line_right{
  position: relative;
  height: 3px;
  background-color: #D7D9DC;
}

.hr-line_right::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  background-color: #00B38F;
  /* change this to the color you want for the left side */
}

.hr-line_left {
  position: relative;
  height: 3px;
  background-color: #D7D9DC;
}

.hr-line_left::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background-color: #00B38F;
  /* change this to the color you want for the left side */
}

.container_ticket{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 400px;

  .blur-effect {
    filter: blur(3px);
  }

  .print__ticket{
    margin: 35px 0px;
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;

    .coming-soon{
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 28%;

      .p-rl{
        font-family: 'Oscar v2 FM';
        font-style: normal;
        font-weight: 300;
        font-size: 25px;
        line-height: 35px;
        text-align: center;
      }

      .p-lr {
        font-family: 'Heebo';
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 35px;
        text-align: center;
      }
    }

    .color__cirlcles {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: auto;
      position: absolute;
      top: 25%;
  
      .custom_circle {
        width: 17px;
        height: 17px;
        border-radius: 22px;
        margin-bottom: 12px;
        cursor: pointer;
      }
  
      .green {
        @extend .custom_circle;
        background-color: #00B38E;
        border: 2px solid #000000;
  
      }
  
      .yellow {
        @extend .custom_circle;
        background-color: #B3A100;
      }
  
      .purple {
        @extend .custom_circle;
        background-color: #5A00B3;
      }
  
      .red {
        @extend .custom_circle;
        background-color: #B30000;
      }
    }
    .pos-right {
      left: 98%;
    }

    .pos-left {
      right: 98%;
    }
  }

  .ticket__text{
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 13.5px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;

    .text-underlined{
      text-decoration-line: underline;
    }
  }

  .print__btn{
    margin: 20px 0px;
    background: #878C97;
    border-radius: 20px;
    border: none;
    width: 200px;
    height: 40px;

    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 31px;
    color: #fff;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

  }
}