.progressBar {
  display: flex;
  overflow: hidden;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 12.25px;
  background-color: #00B38F;
  
}

.strips{
  display: flex;
  overflow: hidden;
  align-items: center;
  position: absolute;
  animation: loaderAnimation 1s infinite linear;
  div{
    position: relative;
    transform: rotate(-20deg);
    margin-left: 0.5rem;
    height: 29px;
    width: 7px;
    background: rgba(235, 236, 238, 0.5);
    
    &:nth-child(3n+1){
      margin-left: 1.5rem;
    }

    &:nth-child(1){
      margin-left: 1rem;
    }
  }
}


@keyframes loaderAnimation {
  0% {
    transform:  translateX(-1%);
    
  }
  50% {
    transform:  translateX(2%);
    
  }
  100% {
    transform:  translateX(2.5%);
     
  }
}
