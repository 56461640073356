.dropdown-content {
  display: block;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  z-index: 1;
  padding: 6px 0px;

  .drop-item {
    color: black;
    padding: 12px 20px;
    text-decoration: none;
    display: block;
    cursor: pointer;
    font-size: 18px;

    &:not(:last-child) {
        border-bottom: 1px solid #EBECEE;
      }

    &:hover {
      background-color: rgb(242, 242, 242);
    }
  }

  .item-mr {
    margin-right: 8px;
  }

  .item-ml {
    margin-left: 8px;
  }
}


.dropsdown {
  position: relative;
  display: inline-block;
  padding: 8px;

  .splitRow{
    position: absolute;
    bottom: 40px;
    left: 0px;
    z-index: 10;
  }
}

.static-table_row{
  box-shadow: rgb(0 0 0 / 10%) 0px 20px 25px -5px,
      rgb(0 0 0 / 30%) 0px 10px 10px -5px;
}

.name_header_font{
  font-family: "Heebo";
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 26px; 
}

.piles_header_font{
    color: #000;
    font-family: Heebo;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.piles_header_font_bold{
  @extend .piles_header_font;
  font-weight: 700;
}

.span_50{
  width: 50%;
}

span.cage_diameter{
  width: 50%;
  color: #000;
  font-family: Heebo;
  font-size: 18px;
  font-weight: 400;
  line-height: 26.5px;
}

span.cage_length{
  @extend .cage_diameter;
}

.cage_len_1{
  width: 45%;
}

.cage_len_2{
  @extend .cage_len_1;
}

.cage_edge{
  width: 10%;
}

.extra_img_icon{
  width: 15px;
  height: 15px;

}

.cage_sketch_preview{
  width: 40px !important; 
  height: 40px !important;
}

span.th_norm{
  color: #000;
  font-family: Heebo;
  font-size: 15px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal; 
}

span.th_bold{
  @extend .th_norm;
  font-weight: 700 !important;
}


span.td_norm{
  color: #000;
  font-family: Heebo;
  font-size: 21px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal; 
}

span.td_bold{
  @extend .td_norm;
  font-weight: 700 !important;
}