.counter_box_count{
    width: 20px;
    height: 20px;
    background-color: #fff;
    border: 1.5px #AFB3B9 solid;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}

.ticket_count{
    font-size: 15px;
    font-weight: bold;
    color: #374051;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    top: 1px;
}

.counter_box_increment, .counter_box_decrement{
    width: 20px;
    height: 20px;
    margin: 0 5px;
    background-color: #EBECEE;
    border: 1px #AFB3B9 solid;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;

    &:hover{
        background-color: #00B38F;
        border-color: #00B38F;
        cursor: pointer;

    }
}
