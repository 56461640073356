.order__table__sm {
    margin-bottom: 55px;
    .first-container {
        border-top: 2px solid #959dac;
        padding-top: 20px;
        @include resp($sm) {
            padding-top: 25px;
        }
        @include resp($md) {
            padding-top: 30px;
        }
    }
    .table__accordion__sm {
        // accordion header
        .accordion__header {
            background-color: $header__bg;
            h1 {
            }
            span {
                img {
                    width: 20px;
                }
            }
        }
        // accordion body
        .accordion__body__sm {
            $bdc: lighten(black, 60);
            border-left: 1px solid $bdc;
            border-right: 1px solid $bdc;
            // table rows
            .table__row {
                // top row unit list
                .top__row {
                    display: grid;
                    grid-template-columns: 1.5fr 2.5fr 3fr 3fr 3fr;
                    // border-bottom: 1.5px solid black;
                    .cell {
                        $br: 2px solid #d2d4d6 !important;
                        border-right: $br;
                        border-bottom: $br;
                        border-top: $br;
                        @include py(7px);
                        &:last-child {
                            border-right: 0px !important;
                        }
                        // para
                        p.para {
                            font-size: 17px;
                            line-height: 19px;
                            font-weight: 500;
                            color: black !important;
                            @include resp($sm) {
                                font-size: 19px;
                            }
                            @include resp($md) {
                                font-size: 22px;
                                line-height: 20px;
                            }
                        }
                        // unit
                        .sm__unit {
                            color: black;
                            font-size: 0.7rem;
                            line-height: 0.85rem;
                            @include resp(600) {
                                font-size: 0.85rem;
                                line-height: 0.95rem;
                            }
                            @include resp($md) {
                                font-size: 1rem;
                                line-height: 1.05rem;
                            }
                        }

                        // icon wrap
                        .img__wrap {
                            display: flex;
                            img {
                                width: 11px;
                            }
                            .bag {
                                width: 14px;
                            }
                        }
                        // icon wrapper
                        .icon__wrap {
                            display: flex;
                            font-size: 14px;
                            @include resp($md) {
                                font-size: 15px;
                            }
                        }
                        // unit and icon wrap
                        .unit__icon__wrap {
                            width: 35px;
                            display: flex;
                            align-items: center;
                        }
                    }
                }
                // bottom row
                .bottom__row {
                    height: 140px;
                    @include resp(425px) {
                        height: 190px;
                    }
                    @include resp(550px) {
                        height: 225px;
                    }
                    @include resp($sm) {
                        height: 250px;
                    }
                    // image wrap
                    .img__wrap {
                        height: 100%;
                        svg {
                            max-width: 250px;
                            @include resp($md) {
                                max-width: 220px;
                            }
                        }
                    }
                    // edit icon
                    .edit__wrap {
                        .edit__btn {
                            border-radius: 20px;
                            border: 1px solid #959dac;
                            padding: 5px 10px;
                            font-size: 15px;
                            color: #959dac;
                            @include resp($sm) {
                                font-size: 20px;
                                padding: 7px 16px;
                                border-radius: 28px;
                            }
                        }
                    }
                }
            }
        }
        // acordion footer
        .accordion__footer {
            .col-4 {
                border: 1px solid $red;
                border-right: 0px;
                background-color: $red_bg;
                text-align: center;
                color: $red;
                font-size: 12px;
                font-weight: 400;
                padding: 8px;
                &:last-child {
                    border-right: 1px solid $red;
                }
                .red {
                    font-weight: bold;
                    font-size: 16.5px;
                    margin-right: 5px;
                    font-weight: 700;
                }
            }
        }
    }
}

// nets table modification
#nets__table__sm {
    // top rows
    .top__row {
        grid-template-columns: 0.75fr 2.25fr 1fr 1fr;
    }
    .cell {
        padding-top: 0px!important;
        padding-bottom: 0px!important;
        .d__cell__row {
            padding-top: 7px;
            padding-bottom: 7px;
        }
    }
    // double row column
    .double__row__cell {
        .d__cell__row {
            width: 100%;
            border-bottom: 2px solid #d2d4d6;
            &:last-child {
                border-bottom: 0px solid transparent;
            }
        }
        // images
        img {
            width: 11px;
            margin-left: 4px;
            margin-right: 4px;
        }
    }
    // icon wrap
    .margin-right {
        margin-right: 4px;
    }
    // margin bottom
    .margin-bottom {
        margin-bottom: 5px;
    }
}

// piles table section 
#piles__table__sm {
    // top rows
    .top__row {
        grid-template-columns: 1fr 1fr 2fr 2.5fr 1.7fr 1.7fr;
    }
    .d__cell__row {
            width: 100%;
            border-bottom: 2px solid #d2d4d6;
            &:last-child {
                border-bottom: 0px solid transparent;
            }
        }
    // double row column
    .double__row__cell {
        padding: 0px!important;
        .d__cell__row {
            width: 100%;
            border-bottom: 2px solid #d2d4d6;
            height: 40px;
            &:last-child {
                border-bottom: 0px solid transparent;
            }
        }
        // images
        img {
            width: 11px;
            margin-left: 4px;
            margin-right: 4px;
        }
    }
    // icon wrap
    .margin-right {
        margin-right: 4px;
    }
    // margin bottom
    .margin-bottom {
        margin-bottom: 5px;
    }
}

// order summery title section 
// #374051
#order__summery__tittle {
    background-color: #374051;
    h1 {
        font-size: 22px;
        @include resp ($sm) {
            font-size: 24px;
        }
        @include resp ($md) {
            font-size: 26px;
        }
    }
}


#order__details {
    padding-top: 10px;
    .summ__detai__container {
        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                border-bottom: 2px solid #dedada;
                padding-top: 10px;
                padding-bottom: 10px;
                @include resp ($sm) {
                    padding-top: 10px;
                    padding-bottom: 10px;
                }
                p.para {
                    font-size: 0.9rem;
                    color: #262b33!important;
                    font-weight: 500;
                    @include resp ($sm) {
                        font-size: 1rem;
                    }
                    .unit {
                        font-size: 0.65rem;
                        @include resp ($sm) {
                            font-size: 0.70rem;
                        }
                    }
                }
                .icon__wrap {
                    margin-right: 10px;
                    font-size: 20px;
                    @include resp ($xs) {
                        font-size: 22px;
                        margin-right: 15px;
                    }
                }
                .right {
                    .para {
                        font-weight: 900;
                        color: $header__bg!important;
                        font-size: 20px;
                    }
                }
                // text area 
                textarea {
                    border: 0px solid transparent;
                    border-radius: 10px;
                    background-color: #eeeeee82;
                }
            }
            // border bottom 
            .border__bottom {
                border-bottom: 0px solid transparent!important;
            }
        }
        .box {
            background-color: $green__color;
            color: #fff;
            text-align: center;
            padding: 5px 7px;
            border-right: 1px solid #fff;
        }
    }
    // save and cancel button 
    .save__cancel {
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        padding-top: 70px;
        padding-bottom: 35px;
        @include resp ($xs) {
            padding-top: 100px;
        }
        @include resp ($sm) {
            padding-top: 135px;
            padding-bottom: 50px;
        }

        button {
            border: 0px;
            border-radius: 50px;
            padding: 15px 45px;
            background-color: #2bc454;
            display: flex;
            align-items: center;
            img {
                width: 30px;
            }
        }
        #save__btn {
            p {
                font-family: "Heebo", sans-serif;
                text-transform: capitalize;
                font-weight: 700;
                font-size: 25px;
                color: white;
                margin-bottom: 0px !important;
                margin-left: 10px;
            }
        }
        #cancel__btn {
            background-color: white;
            border: 1px solid #a3aab8;
            border-radius: 50px;
            padding: 7px 18px;
            margin-top: 25px;
            img {
                width: 13.5px;
            }
            p {
                font-family: "Heebo", sans-serif;
                font-size: 16px;
                color: #a3aab8;
                font-weight: 300;
                margin-left: 8px;
            }
        }
    }

}



// order check out page 
#order__summery__tittle.order__checkout {
    background-color: transparent;
    .container {
        background-color: lighten($color: black, $amount: 95);
        h1 {
            font-weight: 900;
            color: $header__bg!important;
        }
    }
}

// button list 
#btn__list {
    button {
        background-color: #2bc454;
        border: 0px solid transparent;
        border-right: 3px solid white;
        font-family: "Heebo", sans-serif;
        &:last-child {
            border-right: 3px solid #2bc454;
        }
        .bold {
            font-weight: 600;
            @include resp ($xs) {
                font-size: 18px;
            }
        }
        .thin {
            font-weight: 400;
            font-size: 13px;
            @include resp ($xs) {
                font-size: 14px;
            }
        }
    }
}