.right-sidebar {
  width: 160px;
  display: flex;
  flex-direction: column;
  background: #d9d9d9;

  .edit-end-box{
    // box-shadow: rgb(0 0 0 / 10%) 0px 20px 25px -5px,
    //     rgb(0 0 0 / 30%) 0px 10px 10px -5px;
    background: #EBECEE;
    border-radius: 5px;
    cursor: pointer;
    margin: 54px 19px 0px;
    z-index: 10;
  }

  .box-hidden{
    display: none;
  }

  .scrollable-area {
    overflow-y: scroll;
    flex: 1;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .finish_button{
    @extend .save-area;
    &:focus{
      border: none !important;
      color: #FFFFFF !important;
      background: #5f6674 !important;
    }
  }
  .save-area {
    button {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 2px 28px;
      gap: 10px;

      background: #5f6674;
      border-radius: 5px;

      font-style: normal;
      font-weight: 700;
      font-size: 21px;
      line-height: 31px;

      color: #FFFFFF;
      border: none;

      margin: 8px auto 8px !important;

    }
    button:focus{
      border: "2px solid #00B38F";
      color: #00B38F;
      background: #fff;
    }

    button.error:focus{
      border: "2px solid #CD2017";
      color: #CD2017;
      background: #fff;
    }
  }
}

//Nets Right Side-Bar Card
.card__list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;

  .cardItem {
    background-color: white;
    border-radius: 5px;

    .card__text {
      padding: 4px 18px;
    }

    .normal-color {
      color: #000;
    }

    .not-active-color {
      color: #AFB3B9;
    }

    .standard__pad {
      padding: 4px 9px;

      img {
        width: 15px;
      }
    }

    .special__pad {
      padding: 4px 3px;

      img {
        width: 15px;
      }
    }

    svg {
      width: 100%;
    }
  }

  .cardItem.active {
    border: 1px solid #80D9C7;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .cardItem.not-active {
    pointer-events: none;
  }

  .card-hover-icons {
    min-width: 24px;
    min-height: 24px;
    max-width: 24px;
    max-height: 24px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #EBECEE;
    cursor: pointer;
    transition: 0.2s ease-in-out;

    &:hover {
      background-color: #FFFFFF;
    }
  }

  .delete-Icon {
    border: 1px solid #E68F8B;
  }

  .duplicate-Icon {
    border: 1px solid #00B38F;
  }

  .static-table_row {
    box-shadow: rgb(0 0 0 / 10%) 0px 20px 25px -5px,
      rgb(0 0 0 / 30%) 0px 10px 10px -5px;
  }
}

.hoveredItems .cardItem:hover {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

//Rebars Right Side-Bar Card
.card__list_rebars {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;

  .cardItem_Rebars {
    background-color: #EBECEE;
    border-radius: 5px;
    width: 121px;
    height: 75px;

  }

  .cardItem_Rebars.active {
    border: 1px solid #80D9C7;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .cardItem_Rebars.not-active {
    pointer-events: none;
  }

  .rebs-box-inside{
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .card-hover-icons {
    min-width: 22px;
    min-height: 22px;
    max-width: 22px;
    max-height: 22px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #EBECEE;
    cursor: pointer;
    transition: 0.2s ease-in-out;

    &:hover {
      background-color: #FFFFFF;
    }
  }

  .delete-Icon {
    border: 1px solid #E68F8B;
  }

  .duplicate-Icon {
    border: 1px solid #00B38F;
  }

  .static-table_row {
    box-shadow: rgb(0 0 0 / 10%) 0px 20px 25px -5px,
      rgb(0 0 0 / 30%) 0px 10px 10px -5px;
  }
}

.hoveredItems .cardItem_Rebars:hover {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

//Piles Right SideBar
.card__list_piles {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;

  .cardItem_pile {
    background-color: white;
    border-radius: 5px;
    height: 106px;

    .normal-color {
      color: #000;
    }
    
    .not-active-color {
        color: #AFB3B9;
    }

    .card__text {
      padding: 4px 18px;
    }

    .standard__pad {
      padding: 4px 9px;

      img {
        width: 15px;
      }
    }

    .card-top-sect{
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 4px 3px;
      height: 26px;

      .top-row{
        display: flex;
        justify-content: center;
        align-items: center;
      }

      p{
        font-family: "Heebo";
        font-size: 13.5px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }

    .card-bottom-sect{
      display: flex;
      align-items: center;
      width: 100%;
      height: 80px;
      
      p {
        font-family: "Heebo";
        font-size: 13.5px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .card-bottom-left-sect {
      display: flex;
      flex-direction: column;
      width: 70%;

      .left-sect-row{
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      .left-sect-row-bw{
        display: flex;
        align-items: center;
        justify-content: space-evenly;
      }
    }

    .bdr-left{
      border-left: 1px solid #D7D9DC;
    } 

    .bdr-right{
      border-right: 1px solid #D7D9DC;
    }

    .card-bottom-right-sect {
      display: flex;
      flex-direction: column;
      width: 30%;
      height: 80px;

      .section-style{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1px 3px;
      }
    }

    .dark-border_btm {
      border-bottom: 1px solid #878C97;
    }

    .special__pad {
      padding: 1px 3px;

      img {
        width: 15px;
      }
    }

    svg {
      width: 100%;
    }
  }

  .cardItem_pile.active {
    border: 1px solid #80D9C7;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .cardItem_pile.not-active {
    //border: 2px solid #80D9C7;
    pointer-events: none;
  }

  .card-hover-icons {
    min-width: 24px;
    min-height: 24px;
    max-width: 24px;
    max-height: 24px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #EBECEE;
    cursor: pointer;
    transition: 0.2s ease-in-out;

    &:hover {
      background-color: #FFFFFF;
    }
  }

  .delete-Icon {
    border: 1px solid #E68F8B;
  }

  .duplicate-Icon {
    border: 1px solid #00B38F;
  }

  .static-table_row {
    box-shadow: rgb(0 0 0 / 10%) 0px 20px 25px -5px,
      rgb(0 0 0 / 30%) 0px 10px 10px -5px;
  }
}

.hoveredItems .cardItem_pile:hover {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}