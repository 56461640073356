main {
  width: 80%;
  max-width: 1530px;
  margin: auto;
}
label{
  /* font-weight: 500 !important; */
}
input{
  font-size: 18px;
}



@media screen and (max-width: 640px) {
  main {
    width: 95%;
  }
  input{
    width:100% !important; 
  }
  
}

