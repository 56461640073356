#PDF-Preview-main {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;

    .divider {
        border: 1px solid #1F1F1F;
    }

    .divider-gray {
        border: 1px solid #D7D9DC;
    }

    .header-top {
        padding: 5px 40px;
        color: #262729;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        height: 18px;
        width: 860px;
        position: relative;
        top: 600px;

        .branding {
            display: flex;
            align-items: center;
            p {
                font-family: 'Heebo';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
            }
            .created_wr{
                width: 100px;
            }
            .created_wl{
                width: 172px;
            }
            img {
                max-width: 100px;
                width: 100%;
                height: 12px;
                margin-left: 10px;
            }
        }

        .site_mr{
            margin-right: 5px;
        }

        .site_ml{
            margin-left: 5px;
        }
    }

    .header-top.rightToLeft {
        .branding {
            img {
                margin-right: 10px;
                margin-left: 0;
            }
        }
    }

    .header-top.rotate_left {
        left: 548px;
        transform: rotate(90deg);
    }

    .header-top.rotate_right {
        left: -548px;
        transform: rotate(-90deg);
    }

    .header-body {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        padding: 7px 40px;

        .document-type {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            gap: 8px;
            
            text-align: center;
            color: #FFFFFF;
            font-style: normal;
            font-weight: 400;
            font-size: 21px;
            line-height: 31px;
            text-transform: uppercase;
            opacity: 1;
            padding: 5px 20px;

            background: #00B38F 0% 0% no-repeat padding-box;
            border-radius: 20px;
        }

        .document-info {
            width: 300px;
            display: flex;
            flex-direction: column;

            .item {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .key {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 15px;
                    letter-spacing: 0px;
                    color: #000000;
                    opacity: 1;
                }

                .val {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 18px;
                    letter-spacing: 0px;
                    color: #000000;
                    text-transform: uppercase;
                    opacity: 1;
                }
            }
        }

    }

    .name-of-client {
        padding: 2px 40px;

        .name {
            font-size: 22px;
            font-weight: bold;
            color: #E02D36;
        }
    }

    .bussiness-details {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        padding: 5px 40px;

        .item {
            display: flex;
            flex-direction: column;

            .label {
                font: normal normal normal 13px/15px Heebo;
                letter-spacing: 0px;
                color: #000000;
                text-transform: capitalize;
            }

            .val {
                margin-top: 5px;
                font: normal normal bold 16px/22px Heebo;
                letter-spacing: 0px;
                color: #353D4A;
                text-transform: capitalize;
            }

            .val-date {
                margin-top: 5px;
                font: normal normal bold 22px/26px Heebo;
                letter-spacing: 0px;
                color: #E02D36;
                text-transform: uppercase;
            }
        }
    }

    .names {
        display: grid;
        grid-template-columns: 2fr 2fr;
        padding: 5px 40px;

        .item {
            display: flex;
            flex-direction: row;
            align-items: center;

            .item__mr{
                margin-right: 8px;
            }

            .item__ml{
                margin-left: 8px;
            }

            .label {
                font: normal normal normal 13px/15px Heebo;
                letter-spacing: 0px;
                color: #000000;
                text-transform: capitalize;
            }
            .val {
                font: normal normal bold 16px/22px Heebo;
                letter-spacing: 0px;
                color: #353D4A;
                text-transform: capitalize;
            }
        }
    }

    .table-wrapper {
        padding: 20px 40px;
        tbody {
            font-size: 20px;
        }

        .table-head {
            max-width: 15%;
            height: 30px;
            color: #FFFFFF;
            background: transparent linear-gradient(180deg, #374051 0%, #1F252E 100%) 0% 0% no-repeat padding-box;
            border-radius: 10px 10px 0px 0px;
            text-align: center;
            font-weight: bold;
        }

        .main_XY {
            font-family: 'Heebo';
            font-style: normal;
            font-weight: 700;
            font-size: 21px;
            line-height: 31px;
        }

        .sub-head_text {
            font-family: 'Heebo';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #000000;
        }

        .special-net-diagram {
            background: #F7F7F7 0% 0% no-repeat padding-box;
            display: flex;
            justify-content: center;
            padding: 20px;

            .img-1 {
                width: 60%;
            }
            .img-2 {
                width: 22%;
                margin-right: 24px;
                margin-left: 24px;
            }
        }

        th {
            background: #EBECEE 0% 0% no-repeat padding-box;

            img {
                height: 28px;
            }

            .unit {
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: #878C97;
            }
        }

        th, td {
            text-align: center;
        }

        table {
            border-collapse: collapse;
            width: 100%;

            tr {
                border-left: 2px solid #878C97;
                border-right: 2px solid #878C97;
            }

            thead {
                th {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                }
            }

            th.number-container {
                height: 103px;
                justify-content: flex-start;
                div {
                    transform: matrix(0, 1, -1, 0, 0, 0);
                    width: 21px;
                }
                div.rightToLeft {
                    transform: matrix(0, -1, 1, 0, 0, 0);
                }
            }

            tbody > tr:nth-child(even) {
                background: #F5F6F8 0% 0% no-repeat padding-box;
                
                td:last-child {
                    background: #F5F6F8 0% 0% no-repeat padding-box;
                }

                td.extras_bg {
                    background: #F5F6F8 0% 0% no-repeat padding-box;
                }
            }

            tbody > tr:nth-child(odd) {
                
                td:last-child {
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                }

                td.extras_bg {
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                }
            }

            tbody > tr:nth-last-child(3){
                border-bottom: 2px solid #878C97
            }

            tbody {
                td {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                td.preview {
                    padding: 10px;
                    width: 100%;
                    height: 100%;

                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }
        }

        table.order-summary {
            tbody {
                tr {
                    display: grid;
                    grid-template-columns: 3fr 1fr 1fr 1fr;

                    td {
                        padding: 5px 10px;
                        justify-content: flex-start;
                        border-left: 1px solid #dcdcdc;

                        &:first-child {
                            border-left: none;
                        }
                    }

                    &:nth-child(n) {
                        background-color: #F5F6F8;
                        font-weight: 400;
                        border-top: 1px solid #dcdcdc;
                        border-left: 1px solid #dcdcdc;
                        border-right: 1px solid #dcdcdc;

                        td {
                            &:last-child {
                                background-color: #F5F6F8 !important;
                            }
                        }
                    }

                    &:nth-last-child(2) {
                        font-weight: bold;
                        border: 1px solid black;
                        background-color: #dcdcdc !important;

                        td {
                            border-left: 1px solid black;
                            &:first-child {
                                border-left: none;
                            }
                            &:last-child {
                                background-color: #dcdcdc !important;
                            }
                        }
                    }

                    &:nth-last-child(1) {
                        border-left: none;
                        border-right: none;
                        border-right: 1px solid #dcdcdc;

                        td {
                            border-bottom: 1px solid #dcdcdc;
                            &:first-child {
                                border-bottom: none;
                                background: #fff;
                            }
                        }
                    }
                }
            }
        }

        table.order-summary.without-price {
            tbody {
                tr {
                    grid-template-columns: 3fr 1fr 1fr;
                }
            }
        }

        table.order-summary.rightToLeft {
            tbody {
                tr {
                    td {
                        border-right: 1px solid #dcdcdc;
                        &:first-child {
                            border-right: none;
                        }
                        &:last-child {
                            border-right: none;
                            border-left: none;
                        }
                    }

                    &:nth-last-child(2) {
                        td {
                            border-right: 1px solid black;
                            &:last-child {
                                border-right: none;
                                border-left: 1px solid black;
                            }
                        }
                    }
                    &:last-child{
                        &:last-child {
                            border-right: none;
                        }
                    }
                }
            }
        }

        .two-columns {
            display: grid;
            grid-template-columns: 2fr 2fr;

            .single-column {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        }

        table.last {

            tr:nth-last-child(2) {
                background-color: #FFFFFF !important;
                td:nth-last-child(-n+3) {
                    font-weight: bold;
                    border: 1px solid black;
                    background-color: #dcdcdc;
                }
            }

            tr:last-child {
                background-color: #FFFFFF;
                td:nth-last-child(-n+3) {
                    background-color: #F5F6F8 !important;
                    font-weight: 400;
                    border-left: 1px solid #dcdcdc;
                    border-bottom: 1px solid #dcdcdc;
                }
                td:last-child {
                    background-color: #F5F6F8 !important;
                    border-right: 1px solid #dcdcdc;
                }
            }
        }

        table.last.without-price {
            tr:nth-last-child(2) {
                td:nth-last-child(3) {
                    border: none;
                    background-color: #FFFFFF !important;
                }
            }

            tr:last-child {
                td:nth-last-child(3) {
                    background-color: #FFFFFF !important;
                    border: none;
                }
            }
        }

        table.rebar {
            tbody > tr {
                display: grid;
                grid-template-columns: 0.5fr 6fr 1.5fr 1.5fr 1.5fr 2fr 2fr 2fr 2fr 2fr;
            }

            thead > tr {
                display: grid;
                grid-template-columns: 0.5fr 6fr 1.5fr 1.5fr 1.5fr 2fr 4fr 4fr;
            }
        }

        table.rebar.without-price {
            tbody > tr {
                grid-template-columns: 5% 38% 8.8% 6.53% 6.53% 6.53% 8.53% 10% 10%;
            }

            thead > tr {
                grid-template-columns: 5% 38% 8.8% 6.53% 6.53% 6.53% 8.53% 20%;
            }
        }

        table.rebar.last {
            tbody {
                tr:nth-last-child(-n+2) {
                    grid-template-columns: 5% 38% 8.8% 6.53% 6.53% 6.53% 8.53% 10% 10%;
                }
            }
        }

        table.rebar.last.without-price  {
            tbody {
                tr:nth-last-child(-n+2) {
                    grid-template-columns: 5% 37.9% 8.7% 6.52% 6.52% 6.52% 8.52% 10% 10.24%;
                }
            }
        }

        table.standard-net {
            thead > tr {
                display: grid;
                grid-template-columns: 0.5fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr 4fr 4fr;
            }

            tbody > tr {
                display: grid;
                grid-template-columns: 0.5fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr 2fr 2fr 2fr 2fr;
            } 

            tbody {
                td{
                    height: 99.9%;
                }
                
            }
        }


        table.standard-net.without-price {
            thead > tr {
                display: grid;
                grid-template-columns: 5% 11.47% 11% 11% 11% 11% 11% 8.53% 20%;
            }

            tbody > tr {
                display: grid;
                grid-template-columns: 5% 11.47% 11% 11% 11% 11% 11% 8.53% 10% 10%;
            }
        }

        table.standard-net.last {
            tbody {
                tr:nth-last-child(-n+2) {
                    grid-template-columns: 1fr 1.5fr 1.5fr 1.5fr 1.5fr 1.4fr 1.5fr 3fr;
                }
            }
        }

        table.standard-net.last.without-price {
            tbody {
                tr:nth-last-child(-n+2) {
                    grid-template-columns: 5% 11.47% 11% 11% 11% 10.9% 10.9% 8.53% 20.2%;
                }
            }
        }

        table.special-net {

            th.number-container {
                height: 131px;
            }
            
            thead > tr {
                display: grid;
                grid-template-columns: 1fr 1.3fr 5fr 5fr 1.8fr 4fr 4fr;
            }
            tbody {
                td {
                    height: 99.9%;
                }
            }

            tbody > tr {
                display: grid;
                grid-template-columns: 1fr 1.3fr repeat(2, 1fr) 3fr repeat(2,1fr) 3fr 2fr 1.8fr 2fr 2fr 2fr;
            }

            .special-length-container {
                display: grid;
                grid-template-columns: 1fr 1fr 3fr;

                .single-column {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                }

                .division-lenghts {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                }
            }

            .small-font {
                font-size: 12px;
                line-height: 22px;
                border-top: 2px solid transparent;
            }
            
            .len_container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;

                .len_top {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-bottom: 2px solid #D7D9DC;
                }

                .len_bottom{
                    width: 100%;
                    height: 100%;
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                }

            }
        }

        table.special-net.without-price {
            
            thead > tr {
                grid-template-columns: 5% 7.47% 4% 27.5% 27.5% 8.53% 20%;
            }

            tbody > tr {
                grid-template-columns: 5% 7.47% 4% 5.5% 5.5% 16.5% 5.5% 5.5% 16.5% 8.53% 10% 10%;
            }
        }

        table.special-net.last {
            tbody > tr:nth-last-child(-n+2) {
                grid-template-columns: 1fr 1.3fr repeat(9, 1fr) 0.8fr 8.53% 10% 10%;
            }
        }

        table.special-net.last.without-price {
            tbody > tr:nth-last-child(-n+2) {
                grid-template-columns: 1fr 1.3fr repeat(9, 1fr) 0.8fr 8.53% calc(20% + 2px);
            }
        }

        table.pile {

            th.number-container {
                height: 120px;
            }

            thead > tr {
                border-top: 2px solid #878C97;
                display: grid;
                grid-template-columns: 5% 8.47% 10% 30% 19% 7.53% 20%;

                th {
                    padding-left: 0px;
                    padding-right: 0px;
                    padding-bottom: 0px;
                    font-family: "Heebo";
                    font-size: 14px !important;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 19px;
                }
            }

            tbody > tr {
                display: grid;
                grid-template-columns: 5% 8.47% 4.9% 5.1% repeat(2, 3.87%) 4.20% repeat(2, 5.85%) 6.36% repeat(3, 4.70%) 4.9% 7.53% 10% 10%;
            }

            .rings-info {
                display: grid;
                grid-template-columns: 33% 33% 34%;
            }

            .rings-holo-info {
                display: grid;
                grid-template-columns: 50% 50%;
            }

            .center-aligned {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .vertical-center-aligned {
                @extend .center-aligned;
                justify-content: space-evenly;
                flex-direction: column;
            }

            .rebar-info {
                display: grid;
                grid-template-columns: 40% 60%;

                .small-font {
                    font-size: 11px;
                    font-weight: 700;
                }

                .lengths-info {
                    display: grid;
                    grid-template-columns: 33% 33% 34%;
                }
            }

            .single-column {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
            }

            .small-img {
                height: 22px;
            }

            .length-icon {
                height: 28px;
                width: 44px;
            }

            .sprial-info {
                display: grid;
                grid-template-columns: repeat(4, 25%);
            }

            .sprial-info-units {
                display: grid;
                grid-template-columns: repeat(4, 25%);
            }

            .len_container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;

                .len_top {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-bottom: 2px solid #D7D9DC;

                    .total_length {
                        color: var(--100-black, #000);
                        text-align: center;
                        font-family: Heebo;
                        font-size: 21px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }
                }

                .len_bottom {
                    width: 100%;
                    height: 100%;
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                }

                .len_bottom-item {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            .piles-weight{
                display: flex;
                justify-content: center;
                align-items: center;
                height: 52px;
            }
        
            .extras_dark_font {
                font-family: Heebo;
                font-size: 15px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                color: #000;
            }

            .extras_light_font {
                color: #000;
                font-family: Heebo;
                font-size: 13.5px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }

    table.pile.without-price {

        thead > tr {
            grid-template-columns: 5% 8.47% 10% 30% 19% 7.53% 20%;
        }

        tbody > tr {
            grid-template-columns: 5% 8.47% 4.85% 5.15% 3.87% 3.83% 4.20% 18.10% repeat(3,4.70%) 4.9% 7.55% 9.99% 9.99%;
            td{
                padding-left: 0px;
                padding-right: 0px;
                height: 100%;
            }
        }
    }

    table.pile.last {
        tbody > tr:nth-last-child(-n+2) {
            grid-template-columns: 5% 8.47% 4.9% 5.1% repeat(2, 3.87%) 4.20% repeat(2, 5.85%) 6.36% repeat(3, 4.70%) 4.6% 7.58% 20.25%;
        }
    }

    table.pile.last.without-price {
        tbody > tr:nth-last-child(-n+2) {
            grid-template-columns: 5% 8.47% 4.9% 5.1% repeat(2, 3.87%) 4.20% repeat(2, 5.85%) 6.36% repeat(3, 4.70%) 4.6% 7.58% 20.25%;
        }
    }

    .dark-border-right {
        border-right: 2px solid #878C97;
    }
    .dark-border-left {
        border-left: 2px solid #878C97;
    }
    .dark-border-top {
        border-top: 2px solid #878C97;
    }
    .dark-border-bottom {
        border-bottom: 2px solid #878C97;
    }
    .light-border-right {
        border-right: 2px solid #D7D9DC;
    }
    .light-border-left {
        border-left: 2px solid #D7D9DC;
    }
    .light-border-top {
        border-top: 2px solid #D7D9DC;
    }
    .light-border-bottom {
        border-bottom: 2px solid #D7D9DC;
    }
    .rebar-tr {
        height: 105px;
    }
    .net-tr {
        height: 79px;
    }
    .spec_net-tr{
        height: 94px;
    }
    .special_piles-tr {
        height: 119px;
        border-bottom: 2px solid #878C97;
    }
    .mid_piles-tr {
        height: 96px;
        border-bottom: 2px solid #878C97;
    }
    .normal_piles-tr {
        height: 76px;
        border-bottom: 2px solid #878C97;
    }
    .height-inherit {
        height: inherit;
    }
    .steel-text {
        font-size: 11px;
        font-weight: 600;
    }
    .last-element {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            height: 30px;
            width: 30px;
        }
    }

    .last-page {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 60px;
    }

    .footer {
        margin: 0 40px;
        display: flex;
        flex-direction: column;

        .head {
            max-width: 12%;
            height: 30px;
            color: #FFFFFF;
            background: transparent linear-gradient(180deg, #374051 0%, #1F252E 100%) 0% 0% no-repeat padding-box;
            border-radius: 10px 10px 0px 0px;
            text-align: center;
            font-weight: bold;
        }

        .sig-price-table {
            display: flex;
            flex-direction: row;

            .item-sm {
                font: normal normal bold 16px/20px Heebo;
                letter-spacing: 0px;
                color: #000000;
                padding: 15px;
                width: 12%;
                border-left: 1px solid #000;
                border-top: 1px solid #000;
                border-bottom: 1px solid #000;
            }

            .item-lg {
                width: 25%;
                border-left: 1px solid #000;
                border-top: 1px solid #000;
                border-bottom: 1px solid #000;
            }

            .item-lg:last-child {
                border-right: 1px solid #000;
            }
        }

        .confirmation {
            padding: 10px 0px;
            display: flex;
            flex-direction: row;
            gap: 20px;
            align-items: center;

            label {
                margin: 0;
                text-align: left;
                font: normal normal bold 16px/20px Heebo;
                letter-spacing: 0px;
                color: #000000;
            }

            p {
                text-align: left;
                font: normal normal bold 20px/24px Heebo;
                letter-spacing: 0px;
                color: #000000;
            }
        }
    }

    .footer.rightToLeft {
        .sig-price-table {
            .item-sm {
                border-right: 1px solid #000;
            }

            .item-lg {
                border-right: 1px solid #000;
            }

            .item-lg:last-child {
                border-left: 1px solid #000;
            }
        }

        .confirmation {
            label {
                text-align: right;
            }

            p {
                text-align: right;
            }
        }
    }
    
}

.downloadBtn {
    width: 160px;
    padding: 10px;

    font-size: 22px;

    border: none;
    margin-bottom: 20px;
    border-radius: 30px;
    background-color: rgb(43, 196, 84);
    color: #fff;

    display: flex;
    justify-content: space-around;
    align-items: center;
}