.profile-edit-form{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-grow:1;
}

.image-section{  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
}

.image-background{
  position: relative;
  overflow: hidden;
  min-width: 140px;
  min-height: 140px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: darkgray;
  cursor: pointer;
}

.image-background:hover{
  opacity: 0.7;
}

.image-background:hover .edit-text {
  opacity: 1;
}

.image-background:hover .camera{
  opacity: 0;
}

.edit-text {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.text{
  color: #fff;
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
}

.image-background > .camera {
  width: 50px;
}

.profile-info-section{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  flex-grow: 1;
  gap: 10;
  padding:0px 40px
}

@media screen and (max-width: 640px) {
  .profile-info-section{
          padding: 0px;
  }
}