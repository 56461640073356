.login {
	display: flex;
	min-height: 100vh;
}

.left__section {
	background-color: #00b38f;
	color: white;
}

.right__section {
	padding: 0 5rem !important;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: #262729;
	color: white;
}

.brand__icon-cu {
	width: 218px;
	margin-bottom: 14px;
}

.login__text {
	font-weight: 300;
	font-style: normal;
	font-size: 65px;
	line-height: 75px;
	display: flex;
	align-items: center;
	text-align: right;
	color: white;
}

.create__account__text {
	font-weight: 300;
	font-size: 26px;
	color: white;
	margin-bottom: 32px;
}
.email__sent__screen{
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    justify-content: flex-start;
    padding-top: 20%;
		height: 100vh;
}
.email__sent__text{
	font-weight: 400;
	font-size: 22px;
	margin: 40px 0 0 0;
	width: 335px;
	color: #ffffff;
}

.user__name{
	display: block;
	margin-bottom: 20px;
}

.last_name__margin {
	margin-top: 20px;
}

@media screen and (min-width: 576px) {
	.user__name {
		display: flex;
		margin-bottom: 20px;
	}

	.last_name__margin{
		margin-top: 0px;
	}

	.last_name__margin_right {
		margin-right: 15px;
	}

	.last_name__margin_left {
		margin-left: 15px;
	}
}

.user__email{
	margin-top: -24px;
	color: #ffffff;
	font-size: 21px;
}

.login__buttons {
	display: flex;
}

.left__button {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	padding: 10px 25px;

	background-color: #5f6674;
	border-radius: 30px;
}

.right__button {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	padding: 10px 25px;
	background: #00b38f;
	border-radius: 30px;
}

.login__texts {
	display: flex;
}

.left__text {
	font-family: "Heebo";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;

	display: flex;
	align-items: center;
	text-align: right;
	text-decoration-line: underline;

	color: #00b38f;
}

.right__text {
	font-family: "Heebo";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 26px;
	display: flex;
	align-items: center;
	text-align: right;

	color: #ffffff;
}

/* .easybar__screen {
	padding-top: 50px;
	padding-right: 100px;
	position: relative;
} */

.mobile__screen {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 10px;
	padding-bottom: 10px;
	background-color: #00b38f;
}
.mobile__icon {
	width: 150px;
}

.lower__section {
	background-color: #262729;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	min-height: 100vh;
}

/* .mobile__text {
	font-style: normal;
	font-weight: 300;
	font-size: 26px;
	line-height: 37px;
	display: flex;
	align-items: center;
	text-align: center;

	color: #ffffff;
} */

.web__text{
	width: 420px;
	height: 123px;
	font-weight: 300;
	font-size: 36px;
	line-height: 41px;
	display: flex;
	color: #ffffff;
}

.mobile__button1 {
	font-family: "Heebo";
	font-style: normal;
	font-weight: 700;
	font-size: 21px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	padding: 4px 16px;
	background: #00b38f;
	border-radius: 30px;
	border: none;
	color: #ffffff;
}
.mobile__button2 {
	font-family: "Heebo";
	font-style: normal;
	font-weight: 700;
	font-size: 21px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	padding: 4px 16px;
	background-color: #5f6674;
	border-radius: 30px;
	border: none;
	color: #ffffff;
}

.web__button1 {
	font-family: "Heebo";
	font-style: normal;
	font-weight: 700;
	font-size: 21px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	padding: 4px 18px;
	background: #00b38f;
	border: none;
	border-radius: 23px;

	color: #ffffff;
}
.web__button2 {
	font-family: "Heebo";
	font-style: normal;
	font-weight: 700;
	font-size: 21px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	padding: 4px 18px;
	background-color: #5f6674;
	border-radius: 23px;
	border: none;
	color: #ffffff;
}
/* @media only screen and (min-width: 1400px) {
	.easybar__screen {
		left: calc(100% - 1010px);
		top: calc(100% - 830px);
		width: 900px;
		height: 800px;
	}
}

@media only screen and (min-width: 1200px) and (max-width: 1399.98px) {
	.easybar__screen {
		left: calc(100% - 860px);
		top: calc(100% - 780px);
		width: 800px;
		height: 700px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
	.easybar__screen {
		left: calc(100% - 760px);
		top: calc(100% - 730px);
		width: 700px;
		height: 600px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
	.easybar__screen {
		left: calc(100% - 660px);
		top: calc(100% - 680px);
		width: 600px;
		height: 500px;
	}
} */

.flex-one{
  flex: 1;
}

.signup__screen {
	display: flex;
	flex-direction: column;
	justify-items: center;
	justify-content: center;
	/* align-items: center; */
	padding-left: 50px;
	padding-right: 50px;
	min-height: 100vh;
	background-color: #262729;
}

.signup__text {
	font-style: normal;
	font-weight: 300;
	font-size: 45px;
	line-height: 52px;
	display: flex;
	align-items: center;
	text-align: right;

	color: #ffffff;
}

.input__label {
	font-family: "Heebo";
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 22px;
	display: flex;
	align-items: center;
	text-align: right;
	color: #ffffff;
	margin-bottom: 0px;
}

.input__box {
	width:100%;
	/* box-sizing: border-box; */
	height: 38px;
	background-color: white;
}

.checkbox {
  display: block;
  margin-top: 32px;
  margin-right: 3px;
}

.signup_checkbox_input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 24px;
  width: 24px !important;
  margin-top: 7px;
}

.signup_checkbox_input ~ .checkbox__label:before {
  content: "";
  width: 1.5rem;
  height: 1.5rem;
  
  border: 2px solid #fff;
  display: inline-block;
  vertical-align: top;
  margin-top: 7px;
}

.signup_checkbox_input:checked ~ .checkbox__label:before {
  content: url('../../assets/img/tick.svg');
  font-size: 13px;
  background-color: #fff;
  color: #00B38F;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  border-radius: 3px;
}

.checkbox__label {
	cursor: pointer;
	font-size: 14px;
}

.checkbox_label_align_left{
	margin-right: 25px;
	color: white;
	display: inline-block;
    width: 70%;
}
.checkbox_label_align_right{
	margin-left: 25px;
	display: inline-block;
	color: white;
	width: 70%;
	
}

.checkbox__label .highlighted__text {
  color: #00B38F;
}

.checkbox__label .highlighted__text:hover {
  text-decoration: underline;
}

.next__button {
	font-size: 22px;
	font-weight: 700;
	color: #ffffff;
	padding: 4px 18px;
	background: #00b38f;
	border-radius: 30px;
  border: none;
}
.cancel__button{
	background: #5F6674;
	border-radius: 30px;
	color: #ffffff;
	font-size: 22px;
	font-weight: 700;
	padding: 4px 16px;
	border: none;
}

.text1 {
	font-family: "Heebo";
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 26px;
	display: flex;
	align-items: center;
	text-align: right;

	color: #ffffff;
}

.text2 {
	font-family: "Heebo";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;

	display: flex;
	align-items: center;
	text-align: right;
	text-decoration-line: underline;

	color: #00b38f;
	margin: 0 12px;
	cursor: pointer;

}
.highlighted__text{
	color: #00b38f;
	cursor: pointer;
	text-decoration-line: underline;
}

.highlighted__text__no_underline{
	color: #00b38f;
	cursor: pointer;
}

.left__section {
	background-color: #00b38f;
	color: white;
}
.easybar__screen {
	padding-top: 112px;
    padding-right: 88px;
    width: 50vw;
    padding-bottom: 84px;
}
.error__message{
	font-weight: 400;
	font-size: 18px;	
  margin-top: 20px;
	color: #CD2017;
  align-self: center;
}

@media (max-width: 576px){
	.create__account__button {
		margin: 40px 0 12px;
		align-self: center!important;
		text-align: center;
	}
	.create__account__texts{
		flex-direction: column;
		align-self: center;
		align-items: center;
	}
	.text2{
		margin: 0;
	}
	.signup__screen{
		padding: 70px 0;
	}
	.main__landing__page{
		background: #262729;
	}
	.under__development__page{
		padding: 90px 60px;
		background: #262729;
		height: calc(100vh - 94px);
	}
	.next__button{
		font-size: 21px;
	}
	.cancel__button{
		font-size: 21px;
	}
	.checkbox__input{
		width: 18px!important;
	}
	.create__account__text{
		margin: 50px 0 60px 0;
	}
}

@media screen and (max-width:480px) {
	.checkbox_label_align_right{
		width: 62%;
	}
	.checkbox_label_align_left{
		width: 62%;
	}
}