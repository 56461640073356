.cages_shape_val {
	width: 50px !important;
	text-align: center;
	font-size: 15px !important;
	background-color: #fff;
}

.cages_shape_val_error {
	@extend .cages_shape_val;
	color: #cd2017 !important;
}


.side_stage_text{
	color: #000;
	font-family: Heebo;
	font-size: 20px !important;
}


.side_stage_logo_amount{
	height: 20px;
	width: 20px;
	position: relative;
	top: -5px;
}

.side_stage_logo_diameter{
	height: 20px;
	width: 20px;
	position: relative;
	top: -5px;
}
.main_sketch_measurment_size{
	p{
		font-size: 25px !important;
		text-align: center !important;	
	}
}
.base_sketch_measurment_size{
	p{
		font-size: 20px !important;
		text-align: center !important;	
	}
}