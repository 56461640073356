.supplier_popups .modal-dialog {
    max-width: 775px;
}

.package_expire_popup .modal-dialog {
    max-width: 557px;
}


.popup_content{
    text-align: center;
    background: rgba(38, 39, 41, 0.9);
    border-radius: 55px;
    width: 775px;
}

.small_popup_content{
  text-align: center;
  background: rgba(38, 39, 41, 0.9);
  border-radius: 55px;
  width: 557px;
}

.popup_container{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.popup_icon{
    cursor: pointer;
    padding-top: 33px;
}

.popup_heading{
    font-family: 'Oscar v2 FM';
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 41px;
    color: #FFFFFF;
    margin: auto;
}

.popup_heading_heebo{
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 41px;
    color: #FFFFFF;
    margin: auto;
}

.popup_button{
    cursor: pointer;
    width: 440px;
    height: 65px;
    background: #00B38F;
    box-shadow: 4px 4px 30px 10px rgba(0, 0, 0, 0.1);
    border-radius: 56.1765px;
    border-color: transparent;
    color: white;
    margin: auto;

    font-family: 'Heebo';
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 31px;
    color: #FFFFFF;
}

.popup_button_small{
  cursor: pointer;
  width: 124px;
  height: 65px;
  background: #00B38F;
  box-shadow: 4px 4px 30px 10px rgba(0, 0, 0, 0.1);
  border-radius: 56.1765px;
  border-color: transparent;
  color: white;
  margin: auto;

  font-family: 'Heebo';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 31px;
  color: #FFFFFF;
}

.popup_button_md{
    cursor: pointer;
    min-width: 316px;
    width: fit-content;
    height: 65px;
    background: #00B38F;
    box-shadow: 4px 4px 30px 10px rgba(0, 0, 0, 0.1);
    border-radius: 56.1765px;
    border-color: transparent;
    color: white;
    margin: auto;
  
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 31px;
    color: #FFFFFF;
    padding-left: 20px;
    padding-right: 20px;
}

.popup_details{
    cursor: pointer;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    text-decoration-line: underline;
    color: white;
    margin: auto;
    &:hover {
        color: #00B38F;
    }
}

.popup_details_no_hover{
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: white;
    margin: auto;
}

.supplier_name{
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 31px;
    color: #00B38F;
}

.supplier_name_input{
    background: #FFFFFF;
    box-shadow: 4px 4px 30px 10px rgba(0, 0, 0, 0.1);
    border-radius: 56px !important;
    padding: 7px 18px;
    width: 478px;
    height: 65px;

    font-family: 'Heebo';
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 31px;
    text-align: center;

    &::placeholder { 
      font-family: 'Heebo';
      font-style: normal;
      font-weight: 700;
      font-size: 21px;
      line-height: 31px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #D7D9DC;
    }
}

.supplier_name_input_empty{
    background: #FFFFFF;
    box-shadow: 4px 4px 30px 10px rgba(0, 0, 0, 0.1);
    border-radius: 56px !important;
    border: 2px solid #CD2017 !important;
    padding: 7px 18px;
    width: 478px;
    height: 65px;

    font-family: 'Heebo';
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 31px;
    text-align: center;

    &::placeholder { 
      font-family: 'Heebo';
      font-style: normal;
      font-weight: 700;
      font-size: 21px;
      line-height: 31px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #D7D9DC;
    }
}



.detail_message{
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 27px;
    line-height: 40px;
    color: #FFFFFF;
}

.detail_message_2{
    font-family: 'Oscar v2 FM';
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 41px;
    color: #FFFFFF;
    margin-left: 100px;
    margin-right: 100px;
}

.go_back{
    cursor: pointer;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    text-decoration-line: underline;
    color: #00B38F;

}

.package_finished{
    font-family: 'Oscar v2 FM';
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 41px;
}

.package_expire_heading{
    font-family: 'Oscar v2 FM';
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 41px;
    color:#FFFFFF;

    padding-left: 65px;
    padding-right: 65px;
}

.package_expire_heading_heebo{
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 41px;
    color:#FFFFFF;

    padding-left: 65px;
    padding-right: 65px;
}

.package_expire_text{
    font-family: 'Oscar v2 FM';
    font-style: normal;
    font-weight: 400;
    font-size: 27px;
    line-height: 39.66px;
    color:#FFFFFF;

    padding-left: 65px;
    padding-right: 65px;
}

.trial_expire_heading{
    font-family: 'Oscar v2 FM';
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 41px;
    color:#FFFFFF;

    padding-left: 85px;
    padding-right: 85px;
}

.trial_expire_heading_heebo{
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 41px;
    color:#FFFFFF;

    padding-left: 85px;
    padding-right: 85px;
}

// .modal-backdrop{
//     background-size: cover;
//     background-image: url("../../../assets/img/ticket__create/popup_background.png");
//     opacity:1 !important;

// }

.supplier_popups_background{
    background-size: cover;
    background-image: url("../../../assets/img/ticket__create/popup_background.png");
    height: 100vh;
}  