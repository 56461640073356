@mixin minresp($width) {
  @media only screen and (min-width: $width) {
    @content;
  }
}

@mixin maxresp($width) {
  @media only screen and (max-width: $width) {
    @content;
  }
}

.export__wrapper{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 26px;
}

.error__wrapper{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items:center;
  margin-top: 26px;
}

.text__area{
  width: 60%;
  @include maxresp(930px) {
      width: 55%;
  }
}

.icon__area{
  width: 40%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @include maxresp(930px) {
      width: 45%;
    }
}

.export__icons{
  min-width: 38px;
  min-height: 38px;
  max-width: 72px;
  max-height: 72px;
  border:none;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #374051;
  cursor: pointer;
  transition: 0.2s ease-in-out;

  &:hover {
    background-color: #00B38F;
  }
}

.icons__right{
  margin-left: 12px;
}

.icons__left {
  margin-right: 12px;
}

.save__btn{
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  color: #fff;
  line-height: 31px;

  padding: 4.5px 19.5px;
  gap: 7.5px;
  width: 83px;
  height: 40px;  
  border: none;
  border-radius: 20px;
  background: #00B38F;

  &:hover {
    background: rgba(0, 179, 143, 0.9);
    transform: scale(0.98);
  }
}

.bid__btn{
  font-style: normal;
  font-style: 'Heebo';
  font-weight: 400;
  font-size: 21px;
  gap: 7.5px;
  color: #00B38F;
  line-height: 31px;
  margin-right: 12px;
  
  text-align: center;
  width: 6.5rem;
  height: 40px;  
  border: 1px solid #00B38F;
  border-radius: 20px;


  &:hover {
    
    background: #fff;
  }
}

.btn__left{
  font-style: normal;
  font-style: 'Heebo';
  font-weight: 400;
  font-size: 21px;
  gap: 7.5px;
  color: #00B38F;
  line-height: 31px;
  width: 11.5rem;
  margin-left: 12px;
  margin-right: 0;
  text-align: center;
  width: 11.5rem;
  height: 40px;  
  border: 1px solid #00B38F;
  border-radius: 20px;


  &:hover {
    
    background: #fff;
  }
}



.save__error{
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #CD2017;
  margin-left: 12px;
}

.save_error_img{
  margin-left: 12px;
}

.btn__warning {
  width: 120px;
  max-width: 180px;
  background-color: #fff;
  border-radius: 25px;
  padding: 0px 0px 3px 0px;
  font-weight: 600;

  // @include minresp($sm) {
  //   padding: 2px 0px 5px 0px;
  //   font-size: 18px;
  //   width: 120px;
  //   max-width: initial;
  // }
}

.delete__title{
  color: #374051;
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 31px;
  text-align: center;
}