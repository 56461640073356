@import './colors';
// active mark
.active__mark {
    background-color: $dark;
    height: 60px;
    color: #fff !important;

    svg {
        fill: #fff !important;
        path {
            fill: #fff !important;
        }
    }
}
//------------------ new user style section ----------------------
#head {
    background-color: $dark__500;
    height: 60px;
    @include maxresp($xs) {
        height: 93px;
    }
    @include maxresp($sm) {
        height: 100%;
    }
    // navbar
    #navbar {
        .container {
            @include minresp($xxl) {
                max-width: 1830px !important;
            }
        }
        .row {
            img, svg {
                width: 25px;
                margin-right: 20px;
                @include minresp($md) {
                    margin-right: 12px;
                }
            }
            svg {
                fill: #6c757d!important;
                path {
                    fill: #6c757d!important;
                }
            }
            .brand__icon {
                width: 115px;
                @include minresp($xl) {
                    width: 150px;
                }
                @include minresp($xxl) {
                    width: 168px;
                }
            }
        }
        .row.rightToLeft {
            svg {
                margin-right: 0;
                margin-left: 20px;
            }
        }
        // user section
        .user__section {
            position: relative;
            .user {
                h3 {
                    @extend .texts__pro;
                    margin-right: 15px;
                    font-size: 18px;
                    font-weight: 400;
                    @include minresp($lg) {
                        margin-right: 20px;
                    }
                    @include minresp($xl) {
                        font-size: 19px;
                    }
                    @include maxresp($xxl) {
                        font-size: 20px;
                    }
                }
                .user__img {
                    width: 24px;
                }
                .arrow {
                    width: 18px;
                }
            }
        }
        // user area
        #userArea {
            position: absolute;
            right: 0px;
            top: 66px;
            z-index: 2000;
            width: 250px;
            ul {
                li {
                    font-weight: 400;
                    font-size: 18px;
                    cursor: pointer;
                }
            }
        }
        #userArea.rightToLeft {
            left: 0px;
            right: inherit;
        }
    }
    // accordion
    .sm__accordion {
        font-family: $prim__f;
        background-color: $dark;
        .file__icon {
            width: 25px;
        }
        .file__texts {
            font-weight: 400;
            font-size: 14px;
            margin-left: 10px;
        }
        .sm__file__icon {
            width: 20px;
        }
        .sm__file__text {
            font-size: 12px;
        }
    }
}
// main order table section --------------------
#order__details__table {
    @include minresp($md) {
        margin-top: 25px;
    }
    @include minresp($lg) {
        margin-top: 30px;
    }
    @include minresp($xl) {
        margin-top: 35px;
    }
    @include minresp($xxl) {
        margin-top: 40px;
    }
    .container {
        background-color: $light_bg;
        @include maxresp($sm) {
            background-color: #F5F5F5;
        }
    }
    // table header
    .order__header {
        .row {
            @include minresp($md) {
                background: linear-gradient(to top, #1F252E 0%, #374051 100%);
            }
            height: 102px;
            border-radius: 25px 25px 0px 0px;
            // border-bottom: 2px solid $dark__500;
            aside {
                h2 {
                    color: $main__text !important;
                    font-size: 20px;
                    @include minresp($md) {
                        color: white !important;
                    }
                }
                .number {
                    font-weight: 400;
                }
            }
            .cus__border {
                border-bottom: 3px solid lighten($dark__500, $amount: 60);
                @include minresp($md) {
                    border-bottom: 3px solid transparent;
                }
            }
        }
    }
    // table details
    .order__details {
        .collapse:not(.show) {
            @include minresp($md) {
                display: block;
            }
        }
        .btn:not(:disabled):not(.disabled) {
            @include minresp($md) {
                display: none;
            }
        }
        .row {
            @include maxresp($md) {
                margin: 0px !important;
            }
        }
        // date
        .date {
            .date__wrap {
                display: flex;
                flex-flow: column wrap;
                align-items: flex-start;
                @include minresp(400px) {
                    justify-content: space-between;
                    flex-flow: row wrap;
                    align-items: center;
                }
                // @include resp ($md) {
                //     flex-direction: ;
                // }
            }
            .order__sm__texts {
                color: #989898 !important;
                @include minresp($md) {
                    font-size: calc(
                        16px + (22 - 16) * ((100vw - 900px) / (1950 - 900))
                    );
                }
            }
            input {
                width: 216px;
                background-color: $light_bg !important;
                padding: 1px 8px;
                border: 1px solid #353d4a;
                margin-left: 0px;
                border-radius: 25px;
                margin-top: 5px;
            }
        }

        // list style

        // first column style
        .user__column {
            .accordion {
                margin-top: 10px;
                @include minresp($md) {
                    margin-top: 0px;
                }
            }
            .date {
                @include minresp($md) {
                    height: 44.5%;
                }
                @include minresp($xl) {
                    height: 38%;
                }
            }
            #collapseOne {
                @include minresp($md) {
                    height: 50%;
                    // border-top: 3px solid #d2d4d6 !important;
                }
            }
        }
        // accordion style
        .accordion {
            .sm__title {
                display: grid;
                align-items: center;
                justify-items: center;
                grid-template-columns: 30px 1fr 3fr 20px;
                grid-template-rows: 1fr;
                @include minresp(400px) {
                    grid-template-columns: 45px 1fr 3fr 45px;
                }
                .title {
                    @extend .texts__pro;
                    font-weight: bold;
                    color: $dark__500;
                    font-size: 15px;
                    text-transform: capitalize;
                    justify-self: flex-start;
                }
                .icon {
                    width: 22px;
                    justify-self: flex-start;
                }
                .ord__title__btn {
                    padding: 4px 12px;
                    border-radius: 25px;
                    justify-self: center;
                    border: 1.2px solid #383b3e;
                    color: #383b3e;
                    font-weight: 400;
                    font-size: 13px;
                    width: 80%;
                    text-align: center;
                    @include minresp(400px) {
                        font-size: 15px;
                    }
                    // color: ;
                }
                .selected {
                    color: #2bc454;
                    border: 2px solid #2bc454;
                    font-weight: bold;
                }
                .accordion__icon {
                    width: 20px;
                    img {
                        width: 16px;
                    }
                }
                select {
                    width: 150px;
                    margin-right: 10px;
                    padding: 2.5px 17px;
                    border-radius: 25px;
                    border: 1px solid #374051;
                    background-color: transparent !important;
                }
            }

            // large title
            // .lg__title {
            // }

            // accordion body
            .list-group {
                @include minresp($md) {
                    // align-items: center;
                }
                .list-group-title {
                    @include minresp($md) {
                        align-items: center;
                    }
                    @include minresp($xl) {
                        flex-direction: row !important;
                    }
                    p {
                        @extend .texts__pro;
                        color: $main__text;
                        font-weight: bold;
                        font-size: 20px;
                    }
                    button {
                        border-radius: 25px;
                        border: 2px solid $green__color !important;
                        padding: 2.5px 17px;
                        transition: all 0.8s linear;
                        background-color: transparent;
                        img {
                            width: 20px;
                        }
                        span {
                            @extend .texts__pro;
                            font-weight: bold;
                            color: $green__color;
                            margin-left: 10px;
                        }
                        span.rightToLeft { 
                            margin-left: 0px;
                            margin-right: 10px;
                        }
                        &:hover {
                            background-color: $green__color;
                            border: 2px solid $checkColor !important;
                            span {
                                color: white;
                            }
                        }
                    }
                    .edit-container {
                        display: flex;

                        select {
                            width: 200px;
                            margin-right: 10px;
                            padding: 2.5px 17px;
                            border-radius: 25px;
                            border: 1px solid #374051;
                            background-color: transparent !important;
                        }

                        .small-btn {
                            margin-left: 5px;
                            height: 28px;
                            width: 28px;
                            color: white;
                            border-radius: 50%;
                            border: none !important;
                            padding: 4px;
                            transition: none;
                            background-color: #374051;
                            font-size: 13px;
                        }
                    }
                }
                .list-group-item {
                    background-color: $light_bg;
                    display: flex;
                    justify-content: space-between;
                    @include minresp($md) {
                        padding: 5px 0px;
                    }
                    @include maxresp($sm) {
                        background-color: #f5f5f5;
                        padding: .75rem 0rem;
                    }
                }
                .list-group-item:last-child {
                    @include minresp($md) {
                        border-bottom: 2px solid transparent !important;
                    }
                }
            }

            // small text
            .order__sm__texts,
            .inputed__data {
                color: #989898 !important;
                @include minresp($md) {
                    font-size: calc(
                        14px + (22 - 16) * ((100vw - 900px) / (1950 - 900))
                    );
                }
            }
        }
    }
}
// add new item section  -------------------------------
#add__new__item {
    .container {
        // add section
        .add {
            padding-top: 100px;
            padding-bottom: 100px;
            text-align: center;
            @include minresp($md) {
                padding-bottom: 200px;
                padding-top: 50px;
            }
            @include minresp($lg) {
                padding-bottom: 235px;
                padding-top: 70px;
            }
            @include minresp($xl) {
                padding-bottom: 260px;
                padding-top: 85px;
            }
            .plus__icon {
                width: 55px;
            }
            h1 {
                color: #383b3e;
                text-transform: uppercase;
                font-size: 22px;
                font-weight: bold;
                margin-top: 10px;
            }
        }
        // save and cancel section
        .save__cancel {
            display: flex;
            flex-flow: column wrap;
            align-items: center;
            padding-top: 50px;
            padding-bottom: 35px;
            button {
                border: 0px;
                border-radius: 50px;
                padding: 15px 45px;
                background-color: #2bc454;
                display: flex;
                align-items: center;
                img {
                    width: 30px;
                }
                p {
                    text-transform: capitalize;
                    @extend .texts__pro;
                    font-weight: 800;
                    font-size: 25px;
                    color: white;
                    margin-bottom: 0px !important;
                    margin-left: 10px;
                }
            }
            #cancel__btn {
                background-color: white;
                border: 1px solid #a3aab8;
                border-radius: 50px;
                padding: 7px 18px;
                margin-top: 25px;
                img {
                    width: 13.5px;
                }
                p {
                    font-size: 16px;
                    color: #a3aab8;
                    font-weight: 300;
                }
            }
        }
    }
}
// footer section
footer {
    background-color: $dark__500;
    .brand__icon {
        width: 115px;
        @include minresp($xl) {
            width: 150px;
        }
        @include minresp($xxl) {
            width: 168px;
        }
    }
    // container
    .container {
        padding-top: 75px;
        padding-bottom: 75px;
        @include minresp($lg) {
            padding-top: 85px;
            padding-bottom: 85px;
        }
        @include minresp($xl) {
            padding-bottom: 100px;
            padding-top: 100px;
        }
    }
    // left section
    .brand__slogan {
        @include minresp($xxl) {
            max-width: 50%;
            flex: 0 0 50%;
        }
        .wrap {
            img {
                margin-bottom: 10px;
            }
            h3 {
                @extend .texts__pro;
                font-size: 20px;
                font-weight: 400;
                @include minresp($lg) {
                    font-size: 22px;
                }
                @include minresp($xl) {
                    font-size: 24px;
                }
            }
        }
    }

    // right side
    .link__list {
        @include minresp($xl) {
            max-width: 60%;
            flex: 0 0 60%;
        }
        @include minresp($xxl) {
            max-width: 50%;
            flex: 0 0 50%;
        }
        .row {
            @include minresp($xxl) {
                justify-content: space-between;
            }
            h3 {
                @extend .texts__pro;
                font-weight: 400;
                font-size: 16px;
                @include minresp($xl) {
                    font-size: 17px;
                }
            }
            ul {
                list-style: none;
                li a {
                    display: block;
                    @extend h3;
                    color: #959dac;
                }
            }
        }
    }
}

// old user page
#order__details__table
    .order__details
    .accordion
    .list-group
    .list-group-title
    .no__border__bg {
    border: 0px solid transparent !important;
    span {
        color: #353d4a;
    }
    .icon__wrap {
        font-size: 18px;
        margin-left: 10px;
        color: $border;
    }
    .b__border__color {
        border: 2px solid #353d4a;
    }
}

#order__details__table
    .order__details
    .accordion
    .list-group
    .list-group-title
    .b__border__color {
    border-color: $border !important;
    span {
        color: #353d4a;
    }
}

#order__details__table
    .order__details
    .accordion
    .list-group
    .list-group-title
    .edit__icon {
    width: 40px;
    height: 40px;
    font-size: 20px;
    color: white;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #707070 !important;
    border: 0px solid transparent !important;
    margin-left: 10px;
}

// force column
#order__details__table .order__details .accordion .list-group #force__column {
    @include resp($xl) {
        flex-direction: column !important;
    }
    @include resp ($xxl) {
        flex-direction: row !important;
    }
    .bottom {
        margin-top: 10px;
        @include resp ($xxl) {
            margin-top: 0px;
        }
    }
}

