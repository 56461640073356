.cage_section_1{
    position: relative;
}


.cage_sidebar_img_heb{
  img{
    -webkit-transform: scaleX(-1) !important;
    transform: scaleX(-1) !important;  
  }
}

.input_d{
    position: absolute !important;
    top: 76px !important;
    left: 130px !important;
}
.input_d_heb{
  @extend .input_d;
  left: 82px !important;  
}

.input_D{
    position: absolute !important;
    top: 76px !important;
    left: 200px !important;
}

.input_D_heb{
  @extend .input_D;
  left: 12px !important;
}

.input_cage_len{
  position: absolute !important;
  top: 38px !important;
  left: 130px !important;
}
.input_cage_len_heb{
  @extend .input_cage_len;
  left: 82px !important;
}

.input_diameter{
  position: absolute !important;
  top: 38px !important;
  left: 200px !important;
}

.input_diameter_heb{
  @extend .input_diameter;
  left: 13px !important;
}

.input_5{
  position: absolute !important;
  top: 85px !important;
  left: 140px !important;
}

.input_5_heb{
  @extend .input_5;
  left: 12px !important;
}

.input_l1{
  position :absolute !important;
  top: 40px !important;
  left: 31px !important;
}

.label_input{
  position :absolute !important;
  bottom: 30px !important;
  left: 22px !important;
}

.label_input_1{
  position :absolute !important;
  bottom: 30px !important;
  left: 26px !important;
}

.label_input_2{
  position :absolute !important;
  bottom: 35px !important;
  left: 21px !important;
}

.input_l2{
  position :absolute !important;
  top: 40px !important;
  left: 109px !important;
}

.input_l3{
  position :absolute !important;
  top: 40px !important;
  left: 188px !important;
}

.input_at_1{
  position :absolute !important;
  top: 136px !important;
  left: 109px !important;
}

.input_at_2{
  position :absolute !important;
  top: 136px !important;
  left: 188px !important;
}

.cages_sidebar_container{
    width: 280px;
    height: 45px;
    padding-left: 13px;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    button {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 2px 28px;
      gap: 10px;
  
      background: #5f6674;
      border-radius: 5px;
  
      font-style: normal;
      font-weight: 700;
      font-size: 21px;
      line-height: 31px;
  
      color: #ffffff;
      border: none;
    }
  
    button:focus {
      border: "2px solid #00B38F";
      color: #00b38f;
      background: #fff;
    }
  
    button.add_btn_error:focus {
      border: "2px solid #CD2017";
      color: #cd2017;
      background: #fff;
    }
  }

.input_2{
  position: absolute !important;
  top: 76px !important;
  left: 200px !important;
}





input.cages_sidebar_input {
  position: relative;
  width: 120px;
  height: 30px;
  background-color: #fff !important;
  border: 1px solid #afb3b9 !important;
  border-radius: 5px !important;

  font-family: "Heebo";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 13.5px !important;
  line-height: 20px !important;
  text-align: center;
  color: #afb3b9;
  z-index: 8;

  &:focus {
    color: #374051 !important;
  }
}

select.cages_sidebar_input {
  position: relative;
  width: 120px;
  height: 30px;
  background-color: #fff !important;
  border: 1px solid #afb3b9 !important;
  border-radius: 5px !important;

  font-family: "Heebo";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 13.5px !important;
  line-height: 20px !important;
  text-align: center;
  color: #afb3b9 !important;
  z-index: 8;

  background: #ffffff url("../../../../assets/img/nets/arrow_down.svg")
    no-repeat center right;
  background-position: center right 10px;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:focus {
    color: #374051 !important;
  }
}

input.cages_sidebar_input_updated {
  @extend .cages_sidebar_input;
  color: #374051 !important;
}

select.cages_sidebar_input_updated {
  @extend .cages_sidebar_input;
  color: #374051 !important;
}

input.cages_sidebar_input_grey,
select.cages_sidebar_input_grey {
  @extend .cages_sidebar_input;
  background-color: #f5f5f5 !important;
  border: 1px solid #d7d9dc !important;
  color: #afb3b9 !important;
}
input.cages_sidebar_input_error,
select.cages_sidebar_input_error{
  @extend .cages_sidebar_input;
  color: #cd2017 !important;
  border: 2px solid #cd2017 !important;
  &:focus {
    color: #cd2017 !important;
    border: 2px solid #cd2017 !important;
  }
}

input.cages_sidebar_input_updated_error, 
select.cages_sidebar_input_updated_error {
  @extend .cages_sidebar_input;
  color: #cd2017 !important;
  border: 2px solid #cd2017 !important;
  &:focus {
    color: #cd2017 !important;
    border: 2px solid #cd2017 !important;
  }
}

input.cages_sidebar_input_warning,
select.cages_sidebar_input_warning {
  @extend .cages_sidebar_input;
  color: #e99a00 !important;
  &:focus {
    color: #e99a00 !important;
  }
}

.cages_sidebar_footer {
  z-index: 10;
  background-color: #ebecee;
  flex-shrink: 0;
  overflow-x: hidden;
}

.cage_footer_shadow{
  box-shadow: 0px -10px 20px 0px rgba(38, 39, 41, 0.10);
}

.cages_sidebar_label_large {
  font-family: "Heebo";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #374051;
}

.cages_sidebar_label {
  font-family: "Heebo";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #374051;
}


.cage_type {
  border-bottom: 1px solid #d9d9d9;
}

.cage_type_heading {
  font-family: "Heebo";
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 31px;
  margin: 0;
  color: #374051;
}

.cage_type_select {
  display: flex;
  align-items: center;
  margin: 0;
  &:hover {
    cursor: pointer;
  }
}

.cage_type_toggle_left {
  margin: 0;
  width: 69px;
  height: 26px;
  background: #d7d9dc;
  border-radius: 0px 5px 5px 0px;

  font-family: "Heebo";
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #fff;
  user-select: none;

  display: flex;
  justify-content: center;
  align-items: center;
}

.cage_type_toggle_right {
  margin: 0;
  width: 69px;
  height: 26px;
  background: #d7d9dc;
  border-radius: 5px 0px 0px 5px;

  font-family: "Heebo";
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #fff;
  user-select: none;

  display: flex;
  justify-content: center;
  align-items: center;
}

.cage_type_selected {
  background: #5f6674;
  line-height: 20px;
}

.cages_units {
  font-family: "Heebo";
  font-style: normal;
  font-weight: 400;
  font-size: 10.5px;
  line-height: 15px;
  align-items: center;
  color: #878c97;
}


.cages_label_units{
  @extend .cages_units;
  color: #374051 !important;
}
.add_button {
  border-top: 1px solid #d9d9d9;
  padding-top: 8px;
  padding-bottom: 8px;
}

.cages_note {
  display: flex;
  width: 280px;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.cages_weight {
  position: relative;
  height: 45px !important;
}

.cages_weight_total {
  position: absolute;
}

.small_txt {
  @extend .cages_units;
  margin-left: 2px;
  margin-bottom: 2px;
  vertical-align: 1.5px;
  color: #374051;
}

.input_split_2_internal_amount{
  position: absolute !important;
  top: 35px !important;
  left: 130px !important;
}

.input_split_2_internal_amount_heb{
  @extend .input_split_2_internal_amount;
  left: 85px !important;
}

.input_split_2_internal_diameter{
  position: absolute !important;
  top: 35px !important;
  left: 200px !important;
}

.input_split_2_internal_diameter_heb{
  @extend .input_split_2_internal_diameter;
  left: 12px !important;
}

.input_split_2_external_amount{
  position: absolute !important;
  top: 80px !important;
  left: 130px !important;
}
.input_split_2_external_amount_heb{
  @extend .input_split_2_external_amount;
  left: 85px !important;
}

.input_split_2_external_diameter{
  position: absolute !important;
  top: 80px !important;
  left: 200px !important;
}

.input_split_2_external_diameter_heb{
  @extend .input_split_2_external_diameter;
  left: 12px !important;
}


.input_split_3_1{
  position: absolute !important;
  top: 35px !important;
  left: 130px !important;
}

.input_split_3_2{
  position: absolute !important;
  top: 35px !important;
  left: 200px !important;
}

.input_split_3_3{
  position: absolute !important;
  top: 70.5px !important;
  left: 130px !important;
}

.input_split_3_4{
  position: absolute !important;
  top: 70.5px !important;
  left: 200px !important;
}

.input_split_3_5{
  position: absolute !important;
  top: 105.5px !important;
  left: 130px !important;
}

.input_split_3_6{
  position: absolute !important;
  top: 105.5px !important;
  left: 200px !important;
}

.input_split_3_1_heb {
  @extend .input_split_3_1;
  left: 85px !important;
}

.input_split_3_2_heb {
  @extend .input_split_3_2;
  left: 12px !important;
}

.input_split_3_3_heb {
  @extend .input_split_3_3;
  left: 85px !important;
}

.input_split_3_4_heb {
  @extend .input_split_3_4;
  left: 12px !important;
}

.input_split_3_5_heb {
  @extend .input_split_3_5;
  left: 85px !important;
}

.input_split_3_6_heb {
  @extend .input_split_3_6;
  left: 12px !important;
}

.cages_collapsable_img {
  cursor: pointer;
}

.cages_errors_text{
  padding: 5px 0;
  margin: 0;
  border-top: 1px solid #d9d9d9;
}

.cages_error_continer {
  width: 280px;
  height: 25px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
}
