.project-modal {
  width: 435px !important;
}

.head {
  padding: 26px 22px 24px 44px;
  background: #00B38F;
  border-radius: 20px 20px 0px 0px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  h4 {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 31px;

    color: #FFFFFF;
    margin-bottom: 0;
  }

  svg {
    width: 24px;
    height: 24px;
  }
}

.body {
  padding: 43px 45px 36px;
  display: flex;
  flex-direction: column;
  gap: 19px;
  background: #FFF;
}

.main-head {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
}

.custom-form-group {
  display: flex;
  flex-direction: column;

  .logo_label {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  label {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;

    color: #000000;
    margin-bottom: 3px;
  }

  .delete_btn{
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-decoration-line: underline;
    color: #CD2017;
    cursor: pointer;
  }

  .company-logo {
    position: relative;
    overflow: hidden;
    min-width: 340px;
    min-height: 155px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #D9D9D9;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;

    .text {
      color: #fff;
      font-family: 'Heebo';
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 35px;
    }

    .underlined {
      font-family: 'Heebo';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      text-decoration-line: underline;
      color: #000;
    }

    &:hover {
      opacity: 0.7;

      .edit-text {
          opacity: 1;
      }
      .underlined {
        opacity: 0;
      }
    }
  }

  input {
    border: 1px solid #5F6674;
    border-radius: 3.75px;

    padding: 8px;

    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;

    color: #000000;
    background-color: #FFF !important;

    .invalid {
      border: 1px solid #CD2017;
      color: #CD2017;
    }

    &:focus {
      border: 1px solid #00B38F;
    }

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #878C97;
      opacity: 1;
      /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #878C97;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #878C97;
    }

  }
}

.save-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 26px;

  margin-top: 64px;
}

.save-btn {
  background: #00B38F;
  border-radius: 22.5px;

  padding: 4px 18.75px 5px;

  font-family: 'Heebo';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 31px;

  display: flex;
  align-items: center;
  text-align: center;

  color: #FFFFFF;
  border: 1px solid #00B38F;
}

.error-mark {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;

  color: #CD2017;
}

.checkbox__input{
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.signup_checkbox_input{
  cursor: pointer;
}

.checkbox__ml{
  margin-left: 12px;
}

.checkbox__mr{
  margin-right: 12px;
}