.bids-container {
  padding-bottom: 12px;
  width: 100%;

  .bids-font {
    font-family: "Heebo";
    font-style: normal;
    color: #000000;
  }

  p {
    @extend .bids-font;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
  }

  .bids_main_heading {
    display: flex;
    flex-direction: column;
    width: 100%;

    .create-bid_text {
      @extend .bids-font;
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
    }
  }

  .supplier_order_info {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    width: 100%;

    .supplier-label {
      @extend .bids-font;
      padding-right: 40px;
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
    }

    .order-label {
      @extend .bids-font;
      padding-right: 68px;
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
    }

    .custom-dropdown {
      position: relative;
      display: inline-block;

      .dropdown-options {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100% !important;
        max-height: 200px;
        overflow-y: auto;
        background-color: #fff;
        border: 1px solid #ccc;
        z-index: 10;

        .option {
          padding: 10px;
          cursor: pointer;
        }

        .option:hover {
          background-color: #f0f0f0;
        }

        .loading {
          padding: 10px;
          text-align: center;
          color: #999;
        }
      }
    }

    select,
    input {
      width: 290px;
      height: 30px;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
    }
  }

  .error-textt {
    font-family: "Heebo";
    font-family: "Heebo";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    align-items: center;
    color: #cd2017;
  }

  .error_linkk {
    text-decoration: underline;
    color: #cd2017;

    &:hover {
      color: #cd2017;
    }
  }

  .fees-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 30px;

    .std-dev {
      position: relative;
      display: flex;
      align-items: center;
      margin-top: 20px;

      span {
        position: absolute;
        left: 175px;
      }

      input {
        position: absolute;
        left: 205px;
        width: 246px;
        height: 30px;
      }

      .std-val {
        position: absolute;
        left: 485px;
      }
    }

    .ezy-fee {
      position: relative;
      display: flex;
      align-items: center;
      margin-top: 20px;

      .ezy-fee-types {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 40px;
        height: 30px;
        border-radius: 4px;
        cursor: pointer;
      }

      .ezy-Ils {
        @extend .ezy-fee-types;
        left: 160px;
      }

      .ezy-Per {
        @extend .ezy-fee-types;
        left: 205px;
      }

      input {
        position: absolute;
        left: 251px;
        width: 200px;
        height: 30px;
      }

      .calc-ezy-fee {
        position: absolute;
        left: 485px;
      }
    }
  }

  .total-fee {
    margin-top: 10px;
    display: flex;
    flex-direction: column;

    span {
      display: flex;
      align-items: center;
      p:first-of-type {
        margin-right: 90px;
      }
    }
  }

  .pdf-language-menu{
    margin-top: 20px;
    select{
      margin-left: 12px;
      width: 290px;
      height: 30px;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
    }
  }

  .bids-btns {
    margin-top: 22px;

    button {
      width: 165px;
      height: 46px;
      border-radius: 6px;
      border: none;
      color: #fff;
      transition: transform 0.3s ease;
    }

    button:hover {
      transform: scale(1.1);
    }
  }

  .bids_border_btm {
    margin-top: 12px;
    width: 100%;
    border-bottom: 1px solid #000000;
  }
}

.supplier_table_header {
  width: 1765px !important;
}

table.supplier_pricelist_table {

  position: relative;
  display: block;
  overflow: auto;
  white-space: nowrap;
  height: 620px;

  th {
    max-width: 120px !important;
    min-width: 120px !important;

    color: #000;
    background-color: #fff;
    text-align: center;
    font-size: 15px;
    font-family: Heebo;

    position: sticky;
    top: 0;
    z-index: 10;

    &:first-child {
      min-width: 45px !important;
      max-width: 45px !important;
      left: 0;
      z-index: 11;
      box-shadow: 2px 0 4px rgba(0, 0, 0, 0.2);

    }
    &:nth-child(2) {
      min-width: 160px !important;
      max-width: 160px !important;
      left: 45px;
      z-index: 11;
      box-shadow: 2px 0 4px rgba(0, 0, 0, 0.2);
    }

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  tbody {
    tr {
      td {
        max-width: 120px !important;
        min-width: 120px !important;

        &:first-child {
          min-width: 45px !important;
          max-width: 45px !important;

          position: sticky;
          left: 0;
          z-index: 9;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2)  ,-2px 0 4px rgba(0, 0, 0, 0.2);

        }
        &:nth-child(2) {
          min-width: 160px !important;
          max-width: 160px !important;

          position: sticky;
          left: 45px;
          z-index: 9;
          box-shadow: 2px 0 4px rgba(0, 0, 0, 0.2);
        }
      }
    }

    tr:nth-child(odd) {
      td {
        background-color: #e6e6e6;
      }
    }

    tr:nth-child(even) {
      td {
        background-color: #fff;
      }
    }
  }
}

table.supplier_pricelist_table tr:hover td {
  background-color: #e5f7f4 !important;
  }
  

.supplier_name_error {
  text-align: right;
  font-family: "Heebo";
  font-size: 16px;
  color: #cd2017;
}

.supplier_pricing_table_scroll{
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #d2d4d6;
  }

  ::-webkit-scrollbar-thumb {
      background: #d2d4d6;
  }
}

.pricing-dropdown-content {
  display: block;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  z-index: 11;
  padding: 6px 0px;

  .drop-item {
    padding: 8px 20px;
    text-decoration: none;
    cursor: pointer;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #5F6674;

    display: flex;
    align-items: center;
    justify-content: start;

    &:not(:last-child) {
      border-bottom: 1px solid #EBECEE;
    }

    &:hover {
      background-color: rgb(242, 242, 242);
    }
  }

  .item-mr {
    margin-right: 8px;
  }

  .item-ml {
    margin-left: 8px;
  }
}


.pricing-dropsdown {
  cursor: pointer;
  position: relative;
  display: inline-block;
}