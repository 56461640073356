@import "../../../styles/mixins";
@import "../../../styles/breakpoints";

#order-details-new-section {
  margin-top: 50px;
}

.detail-header {
  display: flex;
  flex-direction: row;
  gap: 10px;

  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #374051;

  padding: 17px 45px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;

  p {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    
    color: #FFFFFF;
  }

  p.regular {
    font-weight: 400;
  }
}

.overview {
  display: flex;
  flex-direction: row;

  background: #E5F7F4;
  padding: 27px 45px 18px;
  justify-content: space-between;

  @include maxresp($xl) {
      .oi-r_1 {
        width: 344px!important;
        padding-right: 15px;
      }
      .oi-r_2 {
        width: 340px!important;
        padding-right: 15px;
      } 
      .oi-r_3{
        width: 160px!important;
        padding-right: 10px;
      }
      .oi_4{
        width: 170px!important;
      }
      .oi-l_1 {
        width: 344px !important;
        padding-left: 15px;
      }

      .oi-l_2 {
        width: 340px !important;
        padding-left: 15px;
      }

      .oi-l_3 {
        width: 160px !important;
        padding-left: 10px;
      }
  }

  .overview-item {
    display: flex;
    flex-direction: column;
    gap: 9px;
  
    label {
      font-family: 'Heebo';
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 22px;
  
      color: #5F6674;
      margin-bottom: 0;
    }
  
    input, select {
      background-color: #FFFFFF !important;
      border-radius: 20px !important;
      padding: 7px 18px;
  
      font-family: 'Heebo';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
  
      color: black;
      border: 2px solid black !important;
  
      &::placeholder { 
        color: #878C97;
      }
    }

    select {
      -moz-appearance: none; /* Firefox */
      -webkit-appearance: none; /* Safari and Chrome */
      appearance: none;

      background-repeat: no-repeat, repeat;
      background-position: right .7em top 50%, 0 0;
      background-size: 1.65em auto, 100%;
    }

    select.rightToLeft {
      background-position: left .7em top 50%, 0 0;
    }

    select {
      font-weight: 700;
    }
  
    button {
      background: #00B38F;
      border-radius: 20px;
      padding: 9px 18px;
    
      font-family: 'Heebo';
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 22px;
      
      color: #FFFFFF;
      border: none !important;
      text-align: left;
    }
  
    button.right-text {
      text-align: right;
    }

    svg {
      &:hover {
        path {
          fill: #00B38F;
        }
      }
    }
  }
}

.details {
  display: flex;
  flex-direction: row;
  gap: 44px;

  background: #E5F7F4;
  padding: 0px 45px 44px;

  .detail-section {
    flex: 1;
    display: flex;
    flex-direction: column;

    .detail-item {
      display: flex;
      flex-direction: row;
      gap: 10px;
      padding: 9px 0px;

      border-bottom: 1px solid #AFB3B9;

      &:last-child {
        border-bottom: none;
      }

      p {
        font-family: 'Heebo';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
      }

      p.label {
        color: #878C97;
        width: 145px;
        border-radius: 0px;
      }

      p.value {
        color: black;
      }

      p.bold-text {
        font-weight: 700;
      }

      p.title {
        width: 330px;
        color: #5F6674;
      }

      .icon-btn {
        cursor: pointer;
      }
    }
    .detail-item.title {
      justify-content: space-between;

      svg {
        &:hover {
          path {
            fill: #00B38F;
          }
        }
      }
    }
    @include maxresp($xl) {
      width: 32%;
    }
  }
  @include maxresp($xl) {
    gap: 20px !important;
  }
}

.collapse-button {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  button {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #374051;
    border-radius: 0px 0px 20px 20px;

    height: 40px;
    width: 100px;

    display: flex;
    justify-content: center;
    align-items: center;

    border: none !important;
  }
}

.add-btn {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #374051 !important;
  border-radius: 50%;
  height: 40px;
  width: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: #00B38F !important;
  }
}
