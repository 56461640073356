// mixing
@mixin maxresp($width) {
    @media only screen and (max-width: $width) {
        @content;
    }
}

@mixin minresp($width) {
    @media only screen and (min-width: $width) {
        @content;
    }
}

@mixin resp($width) {
    @media only screen and (min-width: $width) {
        @content;
    }
}

@mixin py($w) {
    padding-top: $w!important;
    padding-bottom: $w!important;
}