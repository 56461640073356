.WithoutPrice {
  grid-template-columns: 7% 7% 24% 10.5% 9% 9.5% 9% 6% 9% 9% !important;
}

.WithPrice{
  grid-template-columns: 2.5fr 2fr 4fr 4fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr !important;
}

.lastWithoutPrice {
  grid-template-columns: 5% 5% 30% 10% 8% 9% 9% 6% 18% !important;
}

.lastWithPrice {
  grid-template-columns: 2.5fr 2fr 4fr 4fr 1.5fr 3fr 3fr !important;
}


input[type=checkbox],
input[type=radio] {
  width: 18px;
  height: 18px;
}

.ticket-order_checkbox{
  cursor: pointer;
}

.main__head{
  p{
    font-family: 'Oscar';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 41px;
    color: #262729;
    margin-bottom: 20px;
  }
}

.bottom__info{
  margin: 15px 0 10px;
  .selection__info{
    display: flex;
    align-items: center;
    .info__text{
      font-family: 'Heebo';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
    }
    .selectAll__link{
      font-family: 'Heebo';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;    
      text-decoration-line: underline;    
      color: #00B38F;
      cursor: pointer;
    }
  }
  .description p{
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 31px;
  }
  .print__tickets-btn{
    margin-top: 10px;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 31px;
    color: #fff;

    padding: 4px 19px 5px;
    gap: 8px;
    border: none;
    width: 152px;
    height: 40px;
    background: #00B38F;
    border-radius: 22px;
    
    &:hover {
        background: rgba(0, 179, 143, 0.9);
        transform: scale(0.98);
    }
  }
}