.inputField {
  border: 2px solid #2bc454;
  background-color: white !important;
  width: 50px;
  border-radius: 5px;
  height: 20px;
  text-align: center;
}
.inputField:focus {
  border: 2px solid #2bc454;
}

.active-bg {
  background-color: #f84343 !important;
}

.net-details {
  background-repeat: no-repeat;
  background-position-y: 85%;
}

@media only screen and (max-width: 768px) {
  .net-details {
    background-position-y: 200%;
    background-size: 100% 90%;
  }
}

.unit__grid {
  display: grid;
  grid-template-columns: 7fr 1fr !important;
}

.bd-ri {
  border-right: 0.9px solid #353d4a;
}

.input-inside {
  border: none;
  background-color: transparent !important;
  width: 100%;
  height: 100%;
}

.cur-pt:hover {
  cursor: pointer;
}

.mobile-height {
  height: 278px;
}

#left__wrapper__one {
  input, select {
    width: 90px;
    border-color: #484848;
  }
  .img__input__wrap {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    max-width: 200px;
    margin-bottom: 15px;
  }
}

.error-input {
  color: #CD2017 !important;
}

.shp_option{
  color : #374051 !important;
}

input[type="number"]:focus {
  border: none;
  outline-color: #2eb48f;
}

.pile_preview_item {
  display: flex;
  flex-direction: column;
  border: 1px solid #c3c3c3;
  margin: 10px;
  background-color: white;
  width: 170px;
  height: 120px;
  cursor: pointer;
}

.pile_preview_item:hover {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.pile_preview_item.active {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

.pile_preview_item_specs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  background-color: #c3c3c3;
  grid-gap: 1px;
  border-top: 1px solid #c3c3c3;

  span {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.defaultNets .cardItem {
  cursor: pointer;
}

.defaultNets .cardItem.active {
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

.defaultNets .cardItem:hover {
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.form-control:focus {
  border: 2px solid #2eb48f !important;
  box-shadow: none !important;
}

.symmetric-checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;

  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  label {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;

    color: #374051;
    margin-bottom: 0;
    cursor: pointer;
  }

  label:before {
    content:'';
    -webkit-appearance: none;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 6px;

    background-color: #FFFFFF;

    border: 2px solid #000000;

    width: 18px;
    height: 18px;
    margin-right: 16px;
  }

  input:checked + label:before {
    background: #FFF;
  }

  input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    left: 7px;
    width: 5px;
    height: 12px;
    border: 3px solid #00B38F;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}

.symmetric-checkbox.right-to-left {

  label:before {
    margin-right: 0px;
    margin-left: 16px;
  }

  input:checked + label:after {
    top: 5px;
    left: initial;
    right: 7px;
    transform: rotate(45deg);
  }
}
