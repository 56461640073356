// system files
@import "colors";
@import "mixins";
@import "breakpoints";
// fonts
@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap");

$prim__f: 'Heebo', sans-serif;

@font-face {
    font-family: "Oscar";
    src: local("Oscar"),
            url("../assets/fonts/oscar.otf") format("truetype");
}

// variable 


// extend
.texts__pro {
    font-family: $prim__f;
    color: $main__text;
}

.bold {
    font-weight: 600;
}


// common style
body {
    font-family: $prim__f;
    overflow-x: hidden;
    background-color: #f5f5f5;
    @include minresp($md) {
        background-color: #f5f5f5;
    }
}
a,
button {
    outline: none;
}
p {
    margin-bottom: 0px;
}
.container {
    @include minresp(1400px) {
        max-width: 1320px !important;
    }
    @include minresp(1800px) {
        max-width: 1720px !important;
    }
}

.border__bottom {
    border-bottom: 1px solid #d2d4d6 !important;
}
.border__top {
    border-top: 1px solid #d2d4d6 !important;
}
label {
    color: $label;
    font-weight: 600;
}
input, select {
    background-color: $formInputbg !important;
    border: 1px solid darken($color: $formInputbg, $amount: 40);
}

:root {
    font-family: $prim__f;
}

.btn-form {
    width: 48%;
    max-width: 180px;
    color: white;
    background-color: #959dac;
    border-radius: 25px;
    //padding: 0px 0px 3px 0px;
    font-weight: 600;
    text-align: center;
    @include minresp($sm) {
        padding: 2px 0px 5px 0px;
        font-size: 18px;
        width: 32%;
        max-width: initial;
    }
    @include minresp($xl) {
        font-size: 20px;
    }
    &:hover {
        background-color: $btn__black;
    }
}

.form__btn__black {
    @extend .btn-form;
    background-color: $btn__black;
    &:hover {
        background-color: #959dac;
    }
}
.form__btn__green{
    @extend .btn-form;;
    background-color: #00B38F;
}

.para {
    font-size: calc(16px + (22 - 16) * ((100vw - 900px) / (1950 - 900)));
    color: #989898 !important;
}

// accordion header sm
.com_acco_head_sm {
    background-color: $header__bg;
    padding: 8px 20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    @include resp ($sm) {
        @include py(11px);
    }
    @include resp ($md) {
        @include py(15px);
    }
    h1 {
        font-size: 20px;
        // font-size: 17px;
        // @include resp($sm) {
        //     font-size: 18.5px;
        // }
        // @include resp($md) {
        // }
    }
}

.cus__btn {
    padding: 4px 15px;
    border-radius: 25px;
    border: 2px solid #959DAC;
    font-size: 14px;
    color: #959DAC;
    font-weight: 500;
}

.cus__btn__blue {
    @extend .cus__btn;
}

.cus__btn__blue:focus {
    background-color: #2eb48f;
}

.h100 {
    height: 100%;
}
.h75 {
    height: 100%;
}
.h50 {
    height: 100%;
}
.h25 {
    height: 100%;
}

// accordion 
.accordion {
    div {
        &:hover {
            text-decoration: none!important;
        }
    }
}

.bold {
    font-weight: bold;
}

.mid__bold {
    font-weight: 500;
}

.total__big {
    p {
        font-size: 1.2rem;
        font-weight: bold;
        @include resp ($xs) {
            font-size: 1.35rem;
        }
        @include resp ($sm) {
            font-size: 1.5rem;
        }
    }
}

input {
    font-weight: 400!important;
}

// Custom Omer
.modal-content {
    background: transparent;
    border: none;
}

.modal-90w {
    max-width: 100% !important;
}

.modal-80w {
    max-width: 80% !important;
}

.modal-70w {
    max-width: 70% !important;
}

.modal-document-w {
    max-width: 1000px !important;
}

@media only screen and (min-width: $xxl) {
    .col-xxl-2 {
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
}

#moreOptCollapse {
    ul {
        display: flex;
    }
    li {
        list-style: none;
        padding: 0;
        padding-top: 6px;
        padding-bottom: 6px;
        width: 125px;
        border-radius: 25px;
        background-color: $btn__black;
        color: white;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        margin-top: 5px;
        text-transform: uppercase!important;
        margin-right: 5px;
    }
}

.bg_gradient {
    background: linear-gradient(to top, #1F252E 0%, #374051 100%) !important;
}

.fw-300 {
    font-weight: 300 !important;
    font-size: calc(16px + (22 - 16) * ((100vw - 900px) / (1950 - 900)));
}

button:disabled {
    opacity: 0.5;
}


.custom-tool-table {
    .dark-border-left {
        border-left: 1.5px solid #9A9A9A !important;
    }
    .dark-border-right {
        border-right: 1.5px solid #9A9A9A !important;
    }
    thead {
        tr {
            height: 80px;
            th {
                border-bottom: none;
            }
        }
    }
    tbody {
        tr {
            height: 80px;
        }
        tr:hover {
            background-color: #FAE9E8 !important;
            td.note-td:last-child {
                background-color: #FAE9E8 !important;
            }
        }
    }
    tbody.last {
        tr {
            border-right: 0.3px solid $table__col__border;
            border-left: 0.3px solid $table__col__border;
        }
        tr:nth-last-child(3) {
            border-bottom: 0.3px solid $table__col__border;
        }
        tr:nth-last-child(-n+2) {
            border-right: none;
            border-left: none;
            background-color: #F5F5F5 !important;
            td:first-child {
                border-right: none !important;
            }
            td {
                border-color: #F5F5F5;
            }
        }
        tr:nth-last-child(2) {
            
            td:nth-last-child(-n+3) {
                border-top: 0.01px solid black;
                border-left: 0.01px solid black;
                border-bottom: 0.01px solid black;

                font-weight: bold;
                background-color: #dcdcdc;
            }
            td:last-child {
                border-right: 0.01px solid black;
            }
        }
        tr:last-child {
            td:nth-last-child(-n+3) {
                border-top: 0.01px solid #F0F0F0;
                border-left: 0.01px solid #F0F0F0;
                border-bottom: 0.01px solid #F0F0F0;

                font-weight: 400;
                border-left: 1px solid #F0F0F0;
                border-bottom: 1px solid #F0F0F0;
                background-color: #FFFFFF;
            }
            td:last-child {
                border-right: 0.01px solid #F0F0F0;
            }
        }
    }
}

.custom-table-alignment{
    td{
        vertical-align: middle;
    }
}

.image-preview .modal-content {
    background-color: white;
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}
  
.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
    border-top: 0;
    border-bottom: 4px dashed;
    border-bottom: 4px solid \9;
    content: "";
}

// #user-analytics {
//     th {
//         position: sticky;
//         top: 0;
//     }
// }

.search-results {
    list-style-type: none;
    position: absolute;
    background-color: #f6f6f6;
    overflow: auto;
    z-index: 2;
    padding: 5px;
    width: 195px;
    border: 1px solid #ddd;
    border-radius: 8px;

    li {
        padding: 8px;
        cursor: pointer;
        border-radius: 8px;
    }

    li:hover {
        background-color: #ddd;
    }
}

.order-summary-table.price {
    tr {
        grid-template-columns: 3fr 1fr 2fr 2fr;
    }
}

.order-summary-table {
    tr {
        border-right: 1px solid $table__col__border;
        border-left: 1px solid $table__col__border;
        display: grid;
        grid-template-columns: 3fr 1fr 2fr;

        td:nth-last-child(-n+2) {
            border-left: 1px solid $table__col__border;
            border-bottom: 1px solid $table__col__border;
            text-align: center;
        }
    }
    tr:nth-child(even) {
        background-color: white;
    }
    tr:nth-child(odd) {
        background-color: #F5F6F8;
    }
    tr:nth-last-child(2) {
        font-weight: bold;
        border: 1px solid black;
        background-color: #dcdcdc;
    }

    .black-border-right {
        border-right: 1px solid black;
    }


    tr:last-child {
        background-color: #FFFFFF !important;
        td:first-child {
            border-right: none !important;
            background-color: #F5F5F5
        }
        td:nth-last-child(-n+2) {
            font-weight: 400;
            border-left: 1px solid $table__col__border;
            border-bottom: 1px solid $table__col__border;
        }
        td:last-child {
            border-right: 1px solid $table__col__border;
        }
    }
}

.price-button {
    border-radius: 20px;
    height: 36px;
    padding: 2px 13px;
    border: 1px solid #374051;
    color: #374051;
}

.w-30 {
    width: 30% !important;
}

.overloaded-wrapper{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    background-color: rgba(0,0,0,0.5);
    justify-content: center;

    .overloaded-spinner {
        position: absolute;
        top: 300px;
        height: 200px;
        width: 250px;
        background: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 16px;
        border: 2px solid #d3d3d3;
    
        .spinner-border {
            width: 4rem;
            height: 4rem;
        }
    }    

}

.spinner-border-centered{
    position: absolute;
    top: 50%;
    left: 50%;
    color: aliceblue;
    width: 4rem;
    height: 4rem;
}

.not-click {
    pointer-events: none;
    overflow: hidden;
}

.admin-piles {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
}

.bottom__table input,select,.para-in {
    font-size: 12px !important;
}

.custom__modal {
    height: auto;
    box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.25);
    border-radius: 3.75px;
}

.custom__child {
    margin: 0px 30px;
    max-width: 500px;
}

.custom__title {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 31px;
    text-align: center;
}

.custom__width{
    width: 560px!important;
}

.custom__btn_wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    gap: 7.5px;

    flex: none;
    order: 0;
    flex-grow: 0;
}

.custom__btn {
    display: flex;
    align-items: center;
    text-align: center;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    padding: 4px 18px;
    line-height: 31px;
    border: none;
    border-radius: 22.5px;

    flex: none;
    order: 0;
    flex-grow: 0;
}


.custom__btn-primary {
    @extend .custom__btn;
    color: #FFFFFF;
    font-weight: 700;
    flex: none;
    order: 1;
    flex-grow: 0;
    &:hover {
        background-color: rgba(0, 179, 143, 0.9);
        transform: scale(0.98);
    }
    &:focus{
        background-color: rgba(0, 179, 143, 0.9);
    }
}

.custom__btn-secondary {
    @extend .custom__btn;
    background: #878C97;
    color: #fff;
    flex: none;
    order: 1;
    flex-grow: 0;
    &:hover{
        background-color: rgba(135, 140, 151, 0.9);
        transform: scale(0.98);
    }
}

.custom__img__wrapper{
    width: 410px;
    height: 192px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom__img{
    max-height: 100%;
}

.modal_width{
    width: 400px !important;
}

.ticket__print_modal_width{
    width: 580px !important;
}

.custom_alert__width{
    width: fit-content;
    max-width: fit-content;
}

input {
    border: 1px solid #5F6674 !important;
    border-radius: 4px !important;
    padding: 8px;

    &:focus{
        outline: 1px solid #00B38F;
        border: 1px solid #00B38F !important;
    }
}

.invalid{
    border: 2px solid #CD2017 !important;
    color: #CD2017 !important;
}

.oscar__large_web {
    font-family: 'Oscar';
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 41px;
    color: #fff;
}

.oscar__medium_web {
    font-family: 'Oscar';
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 41px;
    width: 420px;
    color: #fff;
}

.oscar__small_web {
    font-family: 'Oscar';
    font-style: normal;
    font-weight: 300;
    font-size: 21px;
    line-height: 31px;
    width: 330px;
    color: #fff;
}

.oscar__medium_mobile {
    font-family: 'Oscar';
    font-style: normal;
    font-weight: 300;
    font-size: 25px;
    line-height: 29px;
    width: 315px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #fff;
}

.ticket-modal-document {
    max-width: 1728px !important;
    height: 1214px !important;
}

.modal-new-item-custome {
    max-width: 1347px;
}

// files ----------------------------------- >
@import "new__user__page";
@import "new__user__form";
@import "example-of-order-page";
@import "_example-of-order-page-phone";
@import '_tools';
@import '_cage';
@import '_myOrderScreen';
@import 'smOrderScreen';
@import '_add__item__rebar__net';
