.rebar_left {
  position: relative;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow-y: scroll;
  height: 710px;
  scrollbar-width: none;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d2d4d6;
}

::-webkit-scrollbar-thumb {
  background: 1px solid #d2d4d6;
}

.rebar_sidebar_container {
  width: 280px;
  height: 45px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;

  button {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 2px 28px;
    gap: 10px;

    background: #5f6674;
    border-radius: 5px;

    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 31px;

    color: #ffffff;
    border: none;
  }

  button:focus {
    border: "2px solid #00B38F";
    color: #00b38f;
    background: #fff;
  }

  button.add_btn_error:focus {
    border: "2px solid #CD2017";
    color: #cd2017;
    background: #fff;
  }
}

.sidebar_body {
  padding: 0;
  margin: 0;
  flex-grow: 1;
  overflow: auto;
  overflow-x: hidden;
}

.errors_text {
  padding: 5px 0;
  margin: 0;
}

.rebar_sidebar_label_large {
  font-family: "Heebo";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #374051;
}
.rebar_sidebar_label {
  font-family: "Heebo";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #878c97 !important;
}

.rebar_notes_label {
  @extend .rebar_sidebar_label;
  font-size: 10.5px;
  line-height: 15px;
}

.rebar_units {
  font-family: "Heebo";
  font-style: normal;
  font-weight: 400;
  font-size: 10.5px;
  line-height: 15px;
  align-items: center;
  color: #374051 !important;
}

input.rebar_sidebar_input{
  position: relative;
  width: 120px;
  height: 30px;
  background-color: #fff !important;
  border: 1px solid #afb3b9 !important;
  border-radius: 5px !important;

  font-family: "Heebo";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 13.5px !important;
  line-height: 20px !important;
  text-align: center;
  color: #afb3b9;
  z-index: 8;
}

select.rebar_sidebar_input{
  position: relative;
  width: 120px;
  height: 30px;
  background-color: #fff !important;
  border: 1px solid #afb3b9 !important;
  border-radius: 5px !important;

  font-family: "Heebo";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 13.5px !important;
  line-height: 20px !important;
  text-align: center;
  color: #afb3b9 !important;
  z-index: 8;
  background: #ffffff url("../../../../assets/img/nets/arrow_down.svg")
    no-repeat center right;
  background-position: center right 10px;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:focus {
    color: #374051 !important;
  }
}

input.rebar_sidebar_input_updated,
select.rebar_sidebar_input_updated {
  @extend .rebar_sidebar_input;
  color: #374051 !important;
}

input.rebar_sidebar_input_grey,
select.rebar_sidebar_input_grey {
  @extend .rebar_sidebar_input;
  background-color: #f5f5f5 !important;
}
input.rebar_sidebar_input_angle {
  @extend .rebar_sidebar_input_grey;
  width: 60px;
  height: 30px;
  border: 1px solid #d7d9dc;
}

input.rebar_sidebar_input_radius {
  @extend .rebar_sidebar_input_grey;
    width: 60px;
    height: 30px;
    border: 1px solid #d7d9dc;
}

input.rebar_sidebar_input_name {
  @extend .rebar_sidebar_input;
  width: 145px;
}
input.rebar_sidebar_input_note {
  @extend .rebar_sidebar_input;
  color: #374051 !important;
  width: 240px;
  height: 30px;
}

input.rebar_sidebar_input_radius_error {
  @extend .rebar_sidebar_input_radius;
    border: 2px solid #cd2017 !important;
  
    &:focus {
      border: 2px solid #cd2017 !important;
    }
}

input.rebar_sidebar_input_error,
select.rebar_sidebar_input_error {
  @extend .rebar_sidebar_input;
  border: 2px solid #cd2017 !important;
  &:focus {
    border: 2px solid #cd2017 !important;
  }
}

input.rebar_sidebar_input_updated_error,
select.rebar_sidebar_input_updated_error {
  @extend .rebar_sidebar_input;
  border: 2px solid #cd2017 !important;
  &:focus {
    border: 2px solid #cd2017 !important;
  }
}

input.rebar_sidebar_input_warning, input.rebar_sidebar_input_updated_warning
select.rebar_sidebar_input_warning, select.rebar_sidebar_input_updated_warning {
  @extend .rebar_sidebar_input;
  border: 2px solid #e99a00 !important;
  outline: none !important;
  &:focus {
    border: 2px solid #e99a00 !important;
    outline: none !important;
  }
}

input.rebar_sidebar_input_updated_warning
select.rebar_sidebar_input_updated_warning {
  @extend .rebar_sidebar_input;
  border: 2px solid #e99a00 !important;
  outline: none !important;
  &:focus {
    border: 2px solid #e99a00 !important;
    outline: none !important;
  }
}

.ns_marginRight {
  margin-right: 23px;
}

.ns_marginLeft {
  margin-left: 23px;
}

.ns_box_mr {
  margin-right: 7px;
}

.ns_box_ml {
  margin-left: 7px;
}

.rebar_input_select::-ms-expand {
  display: none;
}

.rebar_input_select::before {
  content: "";
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 14.25px;
  height: 7.5px;
  background-color: #afb3b9;
  pointer-events: none;
}

.rebar_note {
  display: flex;
  width: 280px;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.rebar_sidebar_footer {
  z-index: 10;
  background-color: #ebecee;
  flex-shrink: 0;
  overflow-x: hidden;
}

.rebar_weight {
  position: relative;
  border-bottom: 1px solid #d9d9d9;
  border-top: 1px solid #d9d9d9;
  height: 45px;
}

.rebar_weight_total {
  position: absolute;
}

.rebar_note_img {
  cursor: pointer;
}

.rebar_angle_input {
  width: 60px;
  height: 30px;
  p {
    color: #afb3b9 !important;
  }
}

.rebar_error_continer {
  width: 280px;
  height: 25px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}

.small_txt {
  @extend .rebar_units;
  margin-left: 2px;
  margin-bottom: 2px;
  vertical-align: 1.5px;
  color: #374051;
}

.inputX_1 {
  position: relative;
}

.add_button {
  border-top: 1px solid #d9d9d9;
  padding-top: 8px;
  padding-bottom: 8px;
}

.rebar_sides_label {
  width: 5px;
  color: #374051;
  padding-right: 30px;
}

.rebar_sides_label_heb {
  color: #374051;
  padding-left: 30px;
}

.rebar_sides_height_label {
  color: #374051;
  padding-right: 22px;
}

.rebar_sides_height_label_heb {
  color: #374051;
  padding-left: 22px;
}

.back_img {
  cursor: pointer;
}

.catalog_container {
  overflow-y: scroll;
  height: calc(100% - 25px);
  margin: 0;
  width: 280px;
  overflow-x: hidden;
  scrollbar-width: none;
}

.shape_error {
  border: 2px solid #cd2017 !important;
}

.border_top {
  border-top: 1px solid #d9d9d9;
}

.border_bottom {
  border-bottom: 1px solid #d9d9d9;
}

.ht_25 {
  height: 25px !important;
}

.note_container {
  margin-bottom: 12px;
}

.error_img {
  height: 22px;
  padding-right: 0;
}
.rebar_error_text {
  font-weight: 400;
  font-size: 10.5px;
  line-height: 15px;
  color: #878c97;
}


.footer_shadow{
  box-shadow: 0px -10px 20px 0px rgba(38, 39, 41, 0.10);
}
