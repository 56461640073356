.export__icons {
  min-width: 36px;
  min-height: 36px;
  max-width: 70px;
  max-height: 70px;
  border: none;
  border-radius: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #5F6674;
  cursor: pointer;
  transition: 0.2s ease-in-out;

  &:hover {
    background-color: #00B38F;
  }
}

.icons__right {
  margin-left: 12px;
}

.icons__left {
  margin-right: 12px;
}

.order-dropdown-content {
  display: block;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  z-index: 1;
  padding: 6px 0px;

  .drop-item {
    padding: 8px 20px;
    text-decoration: none;
    cursor: pointer;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #5F6674;

    display: flex;
    align-items: center;
    justify-content: start;

    &:not(:last-child) {
      border-bottom: 1px solid #EBECEE;
    }

    &:hover {
      background-color: rgb(242, 242, 242);
    }
  }

  .item-mr {
    margin-right: 8px;
  }

  .item-ml {
    margin-left: 8px;
  }
}


.order-dropsdown {
  cursor: pointer;
  position: relative;
  display: inline-block;
  padding: 8px;
}