.dynamic-map-container {
  height: 280px;
  width: 100%;
  position: relative;
  border-radius: 5px;
}

.mark-map {
  position: absolute;
  top: 50%;
  left: 50%;
  svg {
    position: absolute;
    bottom: 0px;
    height: 40px;
    width: 12px;
  }
}