
.img__bg{
  position: relative;
  overflow: hidden;
  min-width: 24px;
  min-height: 24px;
  border-radius: 22px;
  background-color: darkgray;

  .user__pic{
    position: absolute;
    width: "100%";
    height: auto;
    margin: 0px;
    padding: 0px;
    left: 0;
  }
}

.img_mr {
  margin-right: 12px;
}

.img_ml {
  margin-left: 12px;
}

// row col-12 col-lg-9 col-xl-7
.header-bar{
  display: flex;
  width: 58%;
}

//col-lg-3 col-12 pl-4
// .header-links{
//   width: 100%;
// }

//col-lg-3 col-xl-5
.user-profile{
  width: 38%;
  padding-right: 40px;
}