
// for 720 and smaller screens
@media only screen and (max-height: 720px) {

    .spopup_modal{
        max-width: 850px;
        height: 655px;
    }
    
    .sclose_button{
        cursor: pointer;
        margin: 10px;
        position: absolute;
        left: 1rem;
        top: 0.5rem;
        background: none;
    }
    
    
    .spopup_content{
        max-width: 850px;
        width: 80%;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #EBECEE;
        z-index: 1000;
    
    }
    
    .spopup_heading_eng{
        font-family: 'Heebo';
        font-style: normal;
        font-weight: 300;
        font-size: 40px;
        line-height: 45px;
        padding-right: 80px;
    }
    
    .spopup_heading_heb{
        font-family: 'Oscar v2 FM';
        font-style: normal;
        font-weight: 300;
        font-size: 40px;
        line-height: 45px;
        padding-left: 30px;
    }
    
    .spopup_text_eng{
        font-family: 'Heebo';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
    }
    .spopup_text_heb{
        font-family: 'Heebo';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
    }
    
    .spopup_left{
        padding-top: 50px;
    }
    
    .spopup_button{
        margin-top: 25px;
        margin-bottom: 40px;
        height: 45px;
        width: 160px;
        background: #00B38F;
        box-shadow: 4px 4px 30px 10px rgba(0, 0, 0, 0.1);
        border-radius: 56.1765px;
        border-color: transparent;
        color: white;
        font-family: 'Heebo';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 31px;
    }
    
    img{
        object-fit: cover;
    }    

    .main_image_onboarding_popup{
        height: 80%;
        width: 80%;
    }

    .onb_pr5{
        padding-right: 1rem !important;
    }
    .onb_pl5{
        padding-left: 1rem !important;
    }
}


// for screens larger than 720
@media only screen and (min-height: 720px) {

    .spopup_modal{
        max-width: 1072px;
        height: 655px;
    }
    
    .sclose_button{
        cursor: pointer;
        margin: 20px;
        position: absolute;
        left: 1rem;
        top: 0.5rem;
        background: none;
    }
    
    
    .spopup_content{
        max-width: 1072px;
        width: 80%;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #EBECEE;
        z-index: 1000;
    
    }
    
    .spopup_heading_eng{
        font-family: 'Heebo';
        font-style: normal;
        font-weight: 300;
        font-size: 50px;
        line-height: 65px;
        padding-right: 110px;
    }
    
    .spopup_heading_heb{
        font-family: 'Oscar v2 FM';
        font-style: normal;
        font-weight: 300;
        font-size: 50px;
        line-height: 65px;
        padding-left: 80px;
    
    }
    
    .spopup_text_eng{
        font-family: 'Heebo';
        font-style: normal;
        font-weight: 400;
        font-size: 27px;
        line-height: 45px;
      
    }
    .spopup_text_heb{
        font-family: 'Heebo';
        font-style: normal;
        font-weight: 400;
        font-size: 27px;
        line-height: 45px;
    }
    
    .spopup_left{
        padding-top: 100px;
    }
    
    
    .spopup_button{
        margin-top: 40px;
        margin-bottom: 50px;
        height: 65px;
        width: 230px;
        background: #00B38F;
        box-shadow: 4px 4px 30px 10px rgba(0, 0, 0, 0.1);
        border-radius: 56.1765px;
        border-color: transparent;
        color: white;
        font-family: 'Heebo';
        font-style: normal;
        font-weight: 700;
        font-size: 21px;
        line-height: 31px;
    }
    
    img{
        object-fit: cover;
    }

    .onb_pr5{
        padding-right: 3rem !important;
    }
    .onb_pl5{
        padding-left: 3rem !important;
    }
}
