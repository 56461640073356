.custom__alert__modal{
  background-color: "#00B38F";
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 18px;
}

.icon_margin_right{
  margin-right: 15px;
}

.icon_margin_left {
  margin-left: 15px;
}

.alert__text{
  color: #fff;
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 31px;
}