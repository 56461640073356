@import "../../styles/colors";

.tool-container {
  height: 691px;
  display: flex;
  position: relative;
  .animation-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background: rgb(255, 255, 255, 0.8);
    z-index: 10;
    display: flex;
    align-items: center;
  }
}

// shape list
.shape__list {
  .left__shaped {
    // shape list
    .shape__wrapper {
      border-bottom: 1px solid #d2d4d6;
      gap: 17px;
      .tool__wrap {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        background-color: #878C97;
        transition: background-color 0.3s;
        padding: 10px;
        cursor: pointer;
        &:hover {
          background-color: $red;
        }
        img {
          width: 100%;
          height: auto;
        }
        &:focus {
          background-color: #2eb48f !important;
          outline-color: #2eb48f;
        }
      }
      .tool__wrap:first-child {
        background-color: $header__bg;
      }
      .tool__wrap:first-child.active {
        background-color: #CD2017;;
      }
      .active {
        background-color: #CD2017;
      }
    }
    .shape__wrapper:first-child {
      padding-top: 0 !important;
    }
    .shape__wrapper:nth-last-child(1) {
      border-bottom: none;
    }
  }
}


.center_rebar_zero_input{
  left: 50% !important;
  top: 50% !important;
}

.canvas-container {
  cursor: url('../../assets/img/icon/pen.svg') 0 30, auto;
}

.canvas-container-grab {
  cursor: grab;
}

.canvas-container-grabing {
  cursor: grabbing;
}