section.forms {
    // width: 100vw;
    min-height: 100vh;
    // background-color: $formBg;
    display: grid;
    align-items: center;
    justify-items: center;
    // label style
    label {
        color: #374051;
        font-size: 13px;
        @include minresp($sm) {
            font-size: 15px;
        }
    }
    label.sup {
        font-size: 20px;
        font-weight: bold;
    }
    .price-list {
        .row {
            max-width: 635px !important;
        }
    }
    .container {
        // common heading
        .heading {
            @extend .texts__pro;
            font-size: 17px;
            @include minresp($sm) {
                font-size: 18px;
            }
        }
        .row {
            max-width: 400px;
            transition: max-width 0.5s;
            @include minresp($sm) {
                max-width: 535px;
            }
        }
        // form header
        .login__modal__header{
            background-color: #00B38F !important;
        }
        .modal-title{
            font-weight: 300;
            font-size: 36px;
        }
        .form__header {
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            background-color: $header__bg;
            h1 {
                @extend .heading;
                line-height: 30px;
            }
            .close__btn {
                width: 20px;
                // background: transparent;
                background: none;
                color: inherit;
                border: none;
                padding: 0;
                font: inherit;
                cursor: pointer;
                outline: inherit;
            }
        }
        // form title
        .form__title {
            h3 {
                @extend .heading;
            }
            span {
                color: $border;
            }
        }

        // form body
        .form__body {
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
            padding-bottom: 35px !important;
            background-color: #262729;
            @include minresp($sm) {
                padding-bottom: 50px !important;
            }
        }

        // common
        .form__header,
        .form__body {
            padding-left: 35px;
            padding-right: 35px;
            @include minresp($sm) {
                padding-left: 55px !important;
                padding-right: 55px !important;
            }
        }
        .form__header{
            padding-top: 50px;
            padding-bottom: 30px;
        }

        input {
            border-radius: 4px !important;
            font-size: 15px;
            @include minresp($sm) {
                height: 38px;
            }
            &::placeholder {
                font-size: 15px;
                transform: translateY(-2px);

                @include minresp($sm) {
                    font-size: 14px;
                }
            }
        }

        // form btn
        .form__btn {
            color: white;
        }
        // radio btns
        .radio__btns {
            padding-top: 25px;
            padding-bottom: 25px;
            @extend .border__bottom;
            @extend .border__top;
            input {
                height: initial;
            }
        }
        // checkbox span
        .form-check {
            .form-check-input {
                transform: scale(1.1);
                @include minresp($sm) {
                    transform: scale(1.2);
                }
            }
            .form-check-label {
                color: $main__text;
                font-size: 13px;
                transform: translateY(-3px);
                text-transform: none;
                font-weight: 600;
                @include minresp($sm) {
                    margin-left: 10px;
                    margin-right: 20px;
                }
            }
        }
    }
}

#projectsSite {
    .container {
        .row {
            max-width: initial;
            @include minresp($xl) {
                max-width: 1110px;
            }
        }

        // left and right
        .input__left,
        .input__right {
            @include minresp($md) {
                display: flex;
                flex-flow: column wrap;
                justify-content: space-between;
            }
        }
        // right input list

        select {
            @include minresp($sm) {
                height: 32px;
            }
            background-color: #f0f0f0;
            font-size: 15px;
            border-radius: 8px !important;
            font-weight: 500;
            color: #959dac;
            option {
                font-size: 15px;
            }
        }
        // select field
        select[target="_blank"] {
            background-color: yellow;
        }
        // label
        label {
            font-size: 15px;
            color: #374051;
            font-weight: 400;
            text-transform: none !important;
        }
        label.cap {
            text-transform: capitalize !important;
        }
        label.bold,
        .bold {
            font-weight: 700;
            color: #374051;
        }
        .border__color {
            color: $border;
            text-decoration: underline;
            cursor: pointer;
        }
        // contact icon 
        .contact__icon {
            width: 21px;
        }
    }
}

.forgot__password__text{
    cursor: pointer;
    color: #00B38F;
    font-size: 12px;
}