$mini__border: 0.3px solid $tool_tbl_head_border;
$tool_bottom_table_fontsize: 15px !important;

.cell__border__right {
  border-right: $mini__border;
}
.cell {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  .mini__cell {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  // paragraph
  .para {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}
.tools {
  // style
  width: 100%;
  // background-color: #5f6673;
  min-height: 100vh;
  // common style
  .container {
    max-width: 1800px !important;
    @include maxresp($xxl) {
      max-width: 1750px !important;
    }
    @include maxresp($xl) {
      max-width: 1350px !important;
    }
    @include maxresp($lg) {
      max-width: 1150px !important;
    }
    @include maxresp($md) {
      max-width: 950px !important;
    }
  }
  p.para {
    font-size: 15px;
  }
  h4 {
    color: #374051;
    font-size: 20px;
    font-weight: bold;
  }
  h6 {
    font-size: 15px;
    color: #4e5055;
  }

  .container {
    .tools__wrapper {
      background-color: $header__bg;
      overflow: hidden;
      border-radius: 25px;
      // top section
      .top__section {
        height: 60px;
        .link__wrap {
          span {
            font-size: 20px;
            color: $filter_circle;
          }
          :hover {
            border-bottom: 6px solid $red;
            color: #fff;
          }
          .active {
            border-bottom: 6px solid $red;
            color: #fff;
            font-weight: 700;
          }
        }
      }
      // close section
      .close__wrapper {
        .icon__wrap {
          font-size: 25px;
          img {
            width: 20px;
          }
        }
      }
      // body section
      .tools__body {
        position: relative;
        background-color: #f7f7f7;

        .animation-container {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          background: rgb(255,255,255, 0.8);
          z-index: 10;
          display: flex;
          align-items: center;
        }
        @include resp($xxl) {
          display: flex;
          justify-content: space-between;
          flex-flow: row wrap;
        }
        // left
        .left {
          background-color: #f7f7f7;
          @include resp($xxl) {
            width: 20% !important;
          }
        }

        // filter section
        .filter {
          h6 {
            font-size: 19px;
            font-weight: 700;
          }
          // filter list
          @at-root .filter__list {
            list-style: none;
            li {
              .icon__wrap {
                width: 20px;
                height: 20px;
                color: $filter_circle;
                border-radius: 50%;
                border: 1px solid $filter_circle;
                font-size: 12px;
                @include resp($xl) {
                  width: 25px;
                  height: 25px;
                  font-size: 14px;
                }
                @include resp($xxl) {
                  width: 28px;
                  height: 28px;
                  font-size: 16px;
                }
              }
              .active {
                background-color: $green__color;
                color: #fff;
                border: none;
              }
            }
          }
          // twiker btn
          @at-root .btn-group {
            button {
              padding: 5px 11px !important;
              letter-spacing: 1px;
              color: black !important;
              border: 1px solid black;
              font-size: 14px;
              background-color: white !important;
              @include resp($xl) {
                padding: 2px 10px;
                font-size: 15px;
              }
              @include resp($xxl) {
                padding: 4px 16px !important;
                font-size: 16px;
              }
            }
            .active {
              background-color: $header__bg !important;
              color: white !important;
            }
          }
        }
        // shape list
        .shape__list {
          .left__shaped {
            // shape list
            .shape__wrapper {
              border-bottom: 1px solid #d2d4d6;
              gap: 20px;
              .tool__wrap {
                width: 45px;
                height: 45px;
                border-radius: 50%;
                background-color: #9A9A9A;
                transition: background-color 0.3s;
                padding: 10px;
                cursor: pointer;
                &:hover {
                  background-color: $red;
                }
                img {
                  width: 100%;
                  height: auto;
                }
                &:focus {
                  background-color: #2eb48f !important;
                  outline-color: #2eb48f;
                }
              }
              .tool__wrap:first-child {
                background-color: $header__bg;
              }
              .tool__wrap:first-child.active {
                background-color: $red;
              }
              .active {
                background-color: $red;
              }
            }
            .shape__wrapper:first-child {
              padding-top: 0 !important;
            }
            .shape__wrapper:nth-last-child(1) {
              border-bottom: none;
            }
          }
        }
        // right shape
        .right__shape {
          img {
            @include resp($xxl) {
              width: 140px !important;
            }
          }
        }
      }
    }
  }
}

// right section table
.tools {
  .right {
    padding-left: 12px;
    padding-right: 12px;
    border-bottom-left-radius: 25px;
    background-color: #f0f0f0;
    @include resp($lg) {
      padding-left: 25px;
      padding-right: 25px;
    }
    @include resp($xxl) {
      padding-left: 35px;
      padding-right: 35px;
      padding-top: 35px !important;
      width: 80% !important;
    }
    // heightlight section
    .highlight__section {
      border-bottom-left-radius: 15px;
    }

    // ------------
    // main img and table
    .highlight__section {
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
      // image table
      .high__img {
        height: 420px;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.3);
        -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.3);
        -moz-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.3);
        @include resp($xl) {
          height: 425px;
        }
        @include resp($xxl) {
          height: 380px;
        }
        .svg__wrap {
          padding-top: 40px !important;
          padding-bottom: 40px !important;
          @include resp($xl) {
            padding-top: 55px !important;
            padding-bottom: 55px !important;
          }
          @include resp($xxl) {
            padding-top: 70px !important;
            padding-bottom: 70px !important;
          }
          svg.svg {
            width: 300px;
            @include resp($lg) {
              width: 320px;
            }
            @include resp($xl) {
              width: 350px;
            }
            @include resp($xxl) {
              width: 420px;
            }
          }
        }
      }
    }

    // right bottom big table -----------------
    .bottom__table {
      width: 100%;
      #inside__table {
        border: 1.2px solid $tool_tbl_head_border;
        background-color: $tool_tbl_head;
        width: 100%;
        // rows
        tr {
          border-bottom: $mini__border;
          border-left: $mini__border;
          border-top: $mini__border;
          td {
            select {
              font-size: $tool_bottom_table_fontsize;
            }
          }
          &:first-child {
            border-bottom: none;
          }
          &:last-child {
            background-color: white !important;
            border-top: none;
            grid-template-rows: 35px !important;
            td {
              border-color: #959dac;
              .para {
                color: #4e5055 !important;
                font-size: $tool_bottom_table_fontsize;
              }
            }
          }
          td {
            padding: 0px !important;
            display: flex;
            flex-flow: column wrap;
            align-items: center;
            justify-content: center;
            text-transform: uppercase !important;
            border-right: 0.9px solid $tool_tbl_head_border;
            // input
            select {
              background-color: white !important;
              padding: 0px;
              border-radius: 0px;
              border: none;
              width: 100%;
              height: 100%;
              padding-left: 5px;
            }
            // paragraph
            .para,
            select {
              font-size: 10px;
              color: black !important;
            }
            img {
              width: 10px !important;
              margin-top: 4px;
              margin-right: 5px;
            }
            // unit cell
            @at-root .unit__cell {
              width: 100%;
              height: 100%;
              background-color: $tool_tbl_head_units;
              display: flex;
              align-items: center;
              justify-content: center;
              border-top: $mini__border;
              .unit.para {
                color: #959dac !important;
                text-transform: lowercase !important;
              }
              // border-top: 0.3px solid lighten($color: #000000, $amount: 70);
            }
            // unit texts
            .unit.para {
              color: #959dac !important;
              text-transform: lowercase !important;
            }
          }

          // row two
          .row__two {
            display: grid !important;
            grid-template-rows: 2fr 1fr;
            grid-template-columns: 1fr;
            justify-items: center;
            align-items: center;
            .cell {
              width: 100%;
              height: 100%;
              border: none;
              .mini__cell {
                border-right: $mini__border;
                &:last-child {
                  border-right: none;
                }
              }
              .mini__cell.rightToLeft {
                border-right: none;
                border-left: $mini__border;
                &:last-child {
                  border-left: none;
                }
              }
              // border-top: 0.3px solid lighten($color: #000000, $amount: 70);
            }
            .amount-te {
              border-bottom: $mini__border;
            }
          }
          // row three
          .row__three {
            display: grid !important;
            grid-template-columns: 1fr;
            grid-template-rows: repeat(3, 1fr);
            justify-items: center;
            align-items: center;
            width: 100%;
            height: 100%;
            .cell {
              width: 100%;
              height: 100%;
              border: none;
              &:first-child {
                border-bottom: $mini__border;
              }
              .mini__cell {
                border-right: $mini__border;
                &:last-child {
                  border-right: none;
                }
              }
              .mini__cell.rightToLeft {
                border-right: none;
                border-left: $mini__border;
                &:last-child {
                  border-left: none;
                }
              }
              // border-top: 0.3px solid lighten($color: #000000, $amount: 70);
            }
          }
        }
        // first row / top row
        .top__row {
          display: grid;
          grid-template-columns: 1.5fr 1.5fr 2fr 5fr 4fr 1.75fr 1.5fr 2fr;
        }
        // bottom row
        .bottom__row {
          display: grid;
          grid-template-columns:
            repeat(2, 1.5fr) 2fr repeat(5, 1fr) repeat(4, 1fr)
            1.75fr 1.5fr 1fr 1fr;
        }
      }
      // parent cells
      .parent__cell {
        .cell {
          border-right: $mini__border !important;
          &:last-child {
            border-right: none !important;
          }
        }
        .unit__cell {
          border-top: $mini__border !important;
        }
      }
      .parent__cell.rightToLeft {
        .cell {
          border-right: none !important;
          border-left: $mini__border !important;
          &:last-child {
            border-left: none !important;
          }
        }
      }
    }
    // right bottom btn list
    .bottom__btn__list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #f0f0f0;
      width: 100%;
      @include resp($xxl) {
        align-items: center !important;
      }
      .item {
        @include resp($xl) {
          flex-flow: row wrap !important;
          justify-content: space-between;
          align-items: center !important;
          button {
            margin-top: 0px !important;
          }
          .col__wrap {
            flex-flow: row wrap !important;
          }
        }
        @include resp($xxl) {
          justify-content: space-between;
          .col__wrap {
            justify-content: space-between;
            align-items: center;
          }
        }
      }
      // fix paragraph width
      .fix__para__width {
        // width: 180px;
        width: 119px;
      }
      .para {
        font-size: 16px;
        color: black !important;
        font-weight: 500;
      }
      input {
        background-color: white !important;
        border: none;
        border-radius: 10px;
        height: 28px;
        @include resp($xl) {
          margin-top: 0px !important;
        }
        @include resp($xxl) {
          height: 35px;
        }
      }
      // custom button
      .cus__btn {
        padding: 6px 15px;
        border: none;
        h6 {
          font-size: 13px;
        }
        .icon__wrap {
          font-size: 13px;
        }
        @include resp($xxl) {
          padding: 10px 26px;
          h6 {
            font-size: 16px;
            font-weight: 700;
          }
          .icon__wrap {
            font-size: 16px;
            margin-right: 16px !important;
          }
        }
      }

      // button container
    }
  }
}

// net tools container
.net__tools {
  .shape__list {
    // heading
    h6 {
      font-weight: 700;
      font-size: 19px;
    }
    // left side list
    .input__box {
      background-color: inherit;
      .item {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 15px;
          // width: 17px;
        }
        select {
          width: 100%;
          background-color: #fff !important;
          border-color: lighten($color: #000000, $amount: 80);
          color: lighten($color: #000000, $amount: 0) !important;
          font-weight: 400 !important;
          font-size: 15px !important;
        }
      }
      .up_item{
        display: flex;
        justify-content: center;
        align-items: center;
        .xy__text{
          font-family: 'Heebo';
          font-style: normal;
          font-weight: 700;
          font-size: 15px;
          line-height: 22px;
          color: #374051;
        }
        select {
          width: 70%;
          background-color: #fff!important;
          border-color: lighten($color: #000000, $amount: 80);
          color: lighten($color: #000000, $amount: 0) !important;
          font-weight: 400 !important;
          font-size: 15px !important;

        }
      }
    }

    // image container
    .card__list {
      display: grid;
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 10px;
      grid-column-gap: 10px;
      @include resp($xl) {
        grid-row-gap: 15px;
        grid-column-gap: 15px;
      }
      @include resp($xxl) {
        margin-top: 15px;
      }
      .cardItem {
        background-color: white;
        border-radius: 5px;
        .card__text{
          padding: 4px 18px;
        }
        .standard__pad{
          padding: 4px 9px;
          img{
            width: 15px;
          }
        }
        .special__pad {
          padding: 4px 3px;

          img {
            width: 15px;
          }
        }
        svg {
          width: 100%;
          @include resp($xl) {
            width: 85%;
          }
          @include resp($xxl) {
            width: 78%;
          }
        }
      }
    }
  }
  // main highlight images
  .net__highlight {
    .svg {
      width: 460px !important;
      @include resp($xl) {
        width: 480px !important;
      }
      @include resp($xxl) {
        width: 510px !important;
      }
    }
  }
  // main image
  .pile__high__img {
    @include resp($xxl) {
      padding: 30px !important;
    }
  }

  // table style
  .net__table {
    .border__bottom__none {
      .unit__cell {
        border-bottom: 0px solid transparent !important;
      }
    }
    tr {
      display: grid;
    }
    // top row
    .top__row {
      grid-template-columns: 1fr 2fr 1.5fr 6fr 6fr 1.5fr 2fr !important;
    }
    .top__row.single__value {
      grid-template-columns: 1fr 2fr 1.5fr 1fr 5fr 5fr 1.5fr 2fr !important;
    }
    .top__row.combined__value {
      grid-template-columns: 1fr 2fr 1.5fr 2fr 4fr 4fr 1.5fr 2fr !important;
    }

    .bottom__row {
      grid-template-columns: 1fr 1fr 1fr 1.5fr repeat(12, 1fr) 1.5fr 1fr 1fr !important;
    }
    .bottom__row.single-value {
      grid-template-columns: 1fr 1fr 1fr 1.5fr repeat(11, 1fr) 1.5fr 1fr 1fr !important;
    }
    .bottom__row.combined-values {
      grid-template-columns: 1fr 1fr 1fr 1.5fr repeat(10, 1fr) 1.5fr 1fr 1fr !important;
    }
    // row three
    .three_col_5471 {
      .two {
        display: grid !important;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: 1fr;
      }

      .unit__wrap {
        display: grid !important;
        grid-template-columns: 1fr 1fr 3fr 1fr;
        .unit__cell {
          border-right: $mini__border;
          &:last-child {
            border-right: none;
          }
        }
      }
      .unit__wrap.rightToLeft {
        .unit__cell {
          border-right: none;
          border-left: $mini__border;
          &:last-child {
            border-left: none;
          }
        }
      }
      .single-value__wrap {
        display: grid !important;
        grid-template-columns: 1fr 3fr 1fr;
        .unit__cell {
          border-right: $mini__border;
          &:last-child {
            border-right: none;
          }
        }
      }
      .one__unit{
        grid-template-columns: 3fr;
      }
      .one-column {
        grid-template-columns: 3fr 1fr;
      }
      .two-columns {
        grid-template-columns: 1fr 1fr;
      }
      .three-columns {
        grid-template-columns: repeat(4, 1fr);
      }
      .four-columns {
        grid-template-columns: repeat(5, 1fr);
      }
    }
  }
  // table highlight image
  .high__img {
    // left
    .left {
      @include resp($xxl) {
        width: 60% !important;
      }
    }
  }
}

// pile page
.pile__tools {
  // image slider
  .image__slider {
    width: 100%;
    overflow-x: scroll;
    height: 145px;
    svg {
      height: 100%;
    }
  }
  // image table
  .highlight__section {
    .high__img {
      background-color: white !important;
      height: 420px;
      @include resp($xl) {
        height: 425px;
      }
      @include resp($xxl) {
        height: 380px;
      }
    }
  }
  // main image
  #main__section {
    .left {
      @include resp($xxl) {
        // width: 625px!important;
      }
    }
    .right {
      width: 100% !important;
      @include resp($xxl) {
        // flex-flow: row wrap;
      }
      //.svg__wrap {
        // width: 280px!important;
      //}
    }
    // pile images
    .pile__high__img {
      @include resp($md) {
        height: 445px;
      }
      @include resp($xl) {
        height: 485px;
      }
      @include resp($xxl) {
        height: 445px;
      }
    }
    // highlight image
    .high__img {
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
      // left
      .left {
        @include resp($xxl) {
          padding: 0px !important;
          width: 100% !important;
        }
      }
      // right
      .right {
        @include resp($xxl) {
          padding: 0px !important;
          .svg__wrap {
            width: 100% !important;
            svg {
              width: 190px !important;
            }
            &:last-child {
              svg {
                width: 210px !important;
              }
            }
          }
        }
      }
    }
  }
  // right bottom table
  .bottom__table {
    .table {
      tr {
        display: grid;
      }
      .top__row {
        grid-template-columns: 1.25fr 2.5fr 8fr 4fr 1.25fr 2fr !important;
      }
      .bottom__row {
        grid-template-columns: 1.25fr 1.25fr 1.25fr 1fr 1fr 1fr 1fr 1fr 1.5fr 1.5fr 1fr 1fr 1fr 1fr 1.25fr 1fr 1fr !important;
      }
    }
  }
  // table
  .pile__table {
    .row__three {
      grid-template-rows: 1fr 2fr 1fr !important;
    }
    .row__two {
      grid-template-rows: 3fr 1fr !important;
    }
    // two column
    //.two__col__2412 {
      // grid-template-rows:  35px 80px 35px !important;
    //}
    // three column
    .three_col_5471 {
      // grid-template-rows: 35px 80px 35px !important;
      // .one {
      //     height: 25%;
      // }
      .two {
        display: grid !important;
        grid-template-columns: 6.5fr 1.5fr;
        // .top,
        // .bottom {
        // }
      }
      .unit__wrap {
        display: block !important;
      }
      // lengty column
      .four__col__471 {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
      }
    }
    // amount row
    //.amount__row {
      // grid-template-rows: auto 35px !important;
    //}
    // wight column
    .wight__col {
      grid-template-columns: 1fr !important;
      // grid-template-rows: 35px 80px 35px !important;
    }
    // unit wrapper
    .three_col_5471 {
      .fix__unit__wrap {
        display: grid !important;
        grid-template-columns: 1fr 1fr 1fr 2fr 1.5fr 1.5fr !important;
      }
    }

    // grid row
    .grid__row__4784 {
      display: grid !important;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1.5fr;
      .cell {
        border-right: $mini__border !important;
      }
    }
    #col_9655 {
      @include resp($xxl) {
        width: 100% !important;
      }
    }
    // padding top fix
    #col__6325 {
      @include resp($xxl) {
        padding-top: 0 !important;
      }
    }
  }
  // left image
  #left__img__4547 {
    @include resp($lg) {
      display: grid !important;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      align-items: center;
      justify-items: center;
    }

    // svg wrap
    .svg__wrap {
      @include resp($lg) {
        width: 50% !important;
      }
    }
  }
}
// left wrapper one tab
#left__wrapper__two {
  .input__wrap {
    p {
      font-weight: 700;
      font-size: 17px;
      color: $h__color;
    }
    .img__input__wrap {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;
      align-items: center;
      max-width: 200px;
      margin-bottom: 15px;
      img {
        width: 25px;
      }
      select,
      input {
        width: 90px;
        border-color: #484848;
      }
      p.para {
        font-weight: 500;
        color: #484848 !important;
      }
    }
  }
  // icon wrapper
  .icons {
    max-width: 150px;
    .icon__wrap {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.9rem;
    }
    p.para {
      font-weight: 500;
      color: #696868 !important;
    }
  }
}

.rebar-admin-icon {
  display: flex;
  background: #979797;
  width: 94px;
  height: 94px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;

  img {
    height: 25px;
    width: 50px;
  }
}

.rebar-Filter {
  border-radius: 5px;
  padding: 4px 13px;
  cursor: pointer;
  color: #fff;
  font-size: 12px;
}

