.left-sidebar {
    width: 280px;
    background: #EBECEE;
    height: auto;

    display: flex;
    flex-direction: column;

    .tool-selector {
        height: 45px;
        display: flex;
        gap: 8px;
        padding: 13px 20px 10px;
        border-bottom: 1px solid #D7D9DC;

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 2px 11px;

            height: 22px;

            border-radius: 5px;
            border: 1px solid ;
            border-color: #AFB3B9;

            color: #AFB3B9;

            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
        }

        button.active {
            border-color: #374051;
            color: #374051;
        }
    }

    .sidebar-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #D7D9DC;

        overflow-y: auto;
    }
}