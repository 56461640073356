@import "../../../styles/mixins";
@import "../../../styles/breakpoints";

.company__bar{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 50px 0px;
  width: 100%;
}

.current__company{
  display: flex;
  align-items: center;
  justify-content: center;

  label{
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
  
    display: flex;
    align-items: center; 
    color: #5F6674;
    margin-bottom: 0;
  }

  .label__mr {
    margin-right: 24px;
  }

  .label__ml {
    margin-left: 24px;
  }

  select {
    background-color: #FFFFFF !important;
    border-radius: 20px !important;
    padding: 7px 18px;

    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;

    color: black;
    border: 2px solid black !important;

    &::placeholder { 
      color: #878C97;
    }

    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;

    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: 1.65em auto, 100%;
  }

  select.rightToLeft {
    background-position: left .7em top 50%, 0 0;
  }
}

.new__company{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px 3.75px 10px;
  gap: 7.5px;

  background: #5F6674;
  border-radius: 20px;
  border: none;

  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 31px;

  color: #FFFFFF;

  img {
    width: 18px;
    height: 18px;
  }
}

.noCompany__left{
  padding-right: 50px;
  padding-bottom: 50px;
}

.noCompany__right {
  padding-left: 50px;
  padding-bottom: 50px;
}

.detailss {
  display: flex;
  flex-direction: row;
  gap: 44px;

  padding: 0px 45px 44px;

  .detail-section {
    flex: 1;
    display: flex;
    flex-direction: column;

    .detail-item {
      display: flex;
      flex-direction: row;
      gap: 10px;
      padding: 9px 0px;
      border-bottom: 1px solid #AFB3B9;

      .company-logo {
        position: relative;
        overflow: hidden;
        width: 232px;
        height: 120px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #D9D9D9;
        background-size: contain; 
        background-repeat: no-repeat;
    
        .text {
          color: #fff;
          font-family: 'Heebo';
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 35px;
        }
        .underlined {
          font-family: 'Heebo';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          text-decoration-line: underline;
          color: #000;
        }
        &:hover {
          opacity: 0.7;
          cursor: pointer;
    
          .edit-text {
              opacity: 1;
          }
          .underlined {
            opacity: 0;
          }
        }
      }

      &:last-child {
        border-bottom: none;
      }

      p {
        font-family: 'Heebo';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
      }

      p.label {
        color: #878C97;
        width: 145px;
        border-radius: 0px;
      }

      p.value {
        color: black;
      }

      p.bold-text {
        font-weight: 700;
      }

      p.title {
        width: 330px;
        color: #5F6674;
        ;
      }

      .icon-btn {
        cursor: pointer;
      }
    }

    .logo-detail { 
      justify-content: space-between;  
    }

    .detail-item.title {
      justify-content: space-between;
    }

    @include maxresp($xl) {
      width: 32%;
    }
  }

  @include maxresp($xl) {
    gap: 20px;
  }
}