.primary:focus{
    background: #00B38F;
}

.no_prime{
    background: #878C97;
}

.prime_btn_ {
  background: #00B38F;
}

