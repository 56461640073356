body {
    text-align: right;
}

.list-group {
    padding-right: 0;
}

.edit-container {

    select {
        margin-left: 10px;
    }

    .small-btn {
        margin-right: 5px;
    }
}