.background{
    background-image: url("../../assets/img/login/login-background.png");
    height: 100vh;
    background-size: cover;
}
.verify__email__mobile{
    height: 100vh;
    background:  #262729;
    padding: 0 60px 0 60px;
    position: fixed;
    overflow: hidden;
}
.verify__email__mobile__icon{
    width: 150px;
    margin: 150px 0  40px 0;
}
.verify__email__mobile__text{
    font-size: 36px;
    color: white;
}
.ticker{
    display: flex;
    right: 0px;
    background: #00B38F;
    position: absolute;
    width: 105vw;
    color: white;
}
.ticker span{
    flex: auto;
    padding: 0 10px;
}
.ticker1{
    transform: rotate(-15deg);
    margin: 50px -10px;
}

.ticker2{    
    transform: rotate(7deg);
    margin: 110px -10px;
}

.ticker3{
    transform: rotate(-8deg);
    margin: 6px -10px;
    bottom: 0;
}