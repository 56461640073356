.a4-card{
  width: 432px;
  height: 606px;
  border-radius: 45px;
  background-color: #fff;
  position: relative;

  .top-left-circle{
    position: absolute;
    top: 32px;
    left: 32px;
    width: 60px;
    height: 60px;
    border-radius: 50px;
  }

  .sketch-box{
    width: 100%;
    height: 260px;
    padding-top: 70px;
    display: flex;
    justify-content: center;
    align-items: center;

    img{
      width: 400px;
      height: auto;
    }
  }

  .ticket-border__bottom{
    border-bottom: 1px solid #000 !important;
  }

  .info-section{
    width: 100%;

    .info-pl{
      padding-left: 36px;
    }

    .info-pr{
      padding-right: 36px;
    }

    .sketch-Note{
      font-family: 'Heebo';
      font-style: normal;
      font-weight: 300;
      font-size: 15px;
      line-height: 30px;    
      color: #5F6674;
    }

    .rebar-detail{
      display: flex;
      align-items: center;

      .detail-title{
        font-family: 'Heebo';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: #374051;
      }
      .detail-sub{
        font-family: 'Heebo';
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 35px;
        color: #000000;
      }
    }
    .weight-section{
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 8px 0px;

      p{
        font-family: 'Heebo';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;      
        color: #5F6674;
      }
    }
  }

  .types-section{
    position: absolute;
    left: 18px;
    top: 446px;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    .detail-box{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-right: 11px;


      p{
        font-family: 'Heebo';
        font-style: normal;
        font-weight: 400;
        font-size: 21.2625px;
        line-height: 31px;      
        color: #000000;
      }

      .info-box{
        width: 120px;
        height: 56px;
        background: linear-gradient(0deg, #000000, #000000), #374051;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;

        p{
          font-family: 'Heebo';
          font-style: normal;
          font-weight: 700;
          font-size: 33.075px;
          line-height: 49px;
          color: #FFFFFF;
        }
      }
    }
  }

  .footer-section{
    margin-top: 66px;
    width: 100%;
    height: 96px;
    background: #fff;
    border-radius: 43px 43px 0px 0px;
    transform: rotate(-180deg);

    .order-no{
      transform: rotate(180deg);
      display: flex;
      justify-content: center;

      p{
        font-family: 'Heebo';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 45px;      
        color: #000000;
      }
    }
  }
}

.pdf-grid-container{
  display: grid;
  grid-template-columns: repeat(4,1fr);
  justify-content: center;
}



table{
  width: 396px;
  height: auto;
}

table.tickets-table-net-eng{

  margin: 10px 0 0 0;
  padding: 0;
  text-align: center;
  thead{
    
    th{
      padding-top: 3px;
      padding-bottom: 3px;
      font-size: 6px;
      height: 10px;
      &:nth-child(1){
        border-left: #878C97 2px solid;
        border-right: #878C97 2px solid;
        border-bottom: #878C97 2px solid;
      }
      &:nth-child(2), &:nth-child(3){        
        border-right: #D7D9DC 2px solid;
        border-bottom: #878C97 2px solid;
      }
      &:nth-child(4), &:nth-child(5){        
        border-right: #878C97 2px solid;
        border-bottom: #878C97 2px solid;
      }
    }
    tr{
      background-color: #EBECEE;
      height: 10px !important;
      border: #878C97 2px solid;
    }
  }
  tbody{
    padding: 0;
    margin: 0;
    font-size: 20px;
    tr{
      height: 10px;
      padding: 0;
      margin: 0;
    }
    td{
      &:nth-child(1){
        border-left: #878C97 2px solid;
        border-right: #878C97 2px solid;
        border-bottom: #878C97 2px solid;
      }
      &:nth-child(2), &:nth-child(3){        
        border-right: #D7D9DC 2px solid;
        border-bottom: #878C97 2px solid;
      }
      &:nth-child(4), &:nth-child(5){        
        border-right: #878C97 2px solid;
        border-bottom: #878C97 2px solid;
      }
      height: 10px;
      padding: 0;
      margin: 0;
    }
  }
}

table.tickets-table-net-heb{

  margin: 10px 0 0 0;
  padding: 0;
  text-align: center;
  thead{
    
    th{
      padding-top: 3px;
      padding-bottom: 3px;
      font-size: 7px;
      height: 10px;
      &:nth-child(1){
        border-left: #878C97 2px solid;
        border-right: #878C97 2px solid;
        border-bottom: #878C97 2px solid;
      }
      &:nth-child(2), &:nth-child(3){        
        border-left: #D7D9DC 2px solid;
        border-bottom: #878C97 2px solid;
      }
      &:nth-child(4), &:nth-child(5){        
        border-left: #878C97 2px solid;
        border-bottom: #878C97 2px solid;
      }
    }
    tr{
      background-color: #EBECEE;
      height: 10px !important;
      border: #878C97 2px solid;
    }
  }
  tbody{
    padding: 0;
    margin: 0;
    font-size: 20px;
    tr{
      height: 10px;
      padding: 0;
      margin: 0;
    }
    td{
      &:nth-child(1){
        border-left: #878C97 2px solid;
        border-right: #878C97 2px solid;
        border-bottom: #878C97 2px solid;
      }
      &:nth-child(2), &:nth-child(3){        
        border-left: #D7D9DC 2px solid;
        border-bottom: #878C97 2px solid;
      }
      &:nth-child(4), &:nth-child(5){        
        border-left: #878C97 2px solid;
        border-bottom: #878C97 2px solid;
      }
      height: 10px;
      padding: 0;
      margin: 0;
    }
  }
}

.top_col_1{
  border-bottom: #D7D9DC 2px solid;
}
.bottom_col_1{
  border-left: #D7D9DC 2px solid;
  border-right: #D7D9DC 2px solid;
}

.bottom_sec_1{
  margin: 0;
  padding: 0;
;
}

.v-align{
  margin: 0;
  padding: 0;
  position: relative;
  top: 15px;
}

.net_type_special{
  text-align: right;
  position: absolute;
  top: 50px;
  right: 38px;
}

.net_type_standard{
  text-align: right;
  position: absolute;
  top: 50px;
  right: 38px;
}

.net_nickname_standard{
  text-align: right;
  position: absolute;
  top: 70px;
  right: 38px;
}

.net_nickname_special{
  text-align: right;
  position: absolute;
  top: 70px;
  right: 38px;
}

.txt_left{
  text-align: left;
}


.piles_ticket_piles_data{
  margin-top: 25px;
  border-radius: 7.5px;
  border: 3px solid #D7D9DC;
  background-color: #FFF;
  width: 360px !important;
  display: flex;
  flex-direction: column;

  div{
    height: 30px !important;
    position: relative;
    span{
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;
    }
  }

  div.piles_ticket_top_sec{
    border-bottom: 3px solid #878C97;
    display: flex;
    
    span.pt_total_len{
      width: 33%;
      border-right: 3px solid #878C97;
    }
    
    span.pt_cage_diameters{
      width: 34%;
      border-right: 3px solid #878C97;
    }

    span.pt_rebar_and_sides{
      width: 33%;
    }
  }


  div.piles_ticket_mid_sec {
    display: flex;
    
    span {
      &:first-child {
        width: 25%;
        border-right: 3px solid #D7D9DC;
      }
      
      &:nth-child(2) {
        width: 42%;
        height: 108%;
        padding-bottom: 1px;
        padding-right: 2px;
        padding-left: 0px;
        border-right: 3px solid #878C97;
      }
      
      &:nth-child(3) {
        display: flex;
        width: 33%;

        .pt_side_amount{
          border-right: 3px solid #D7D9DC;
          height: 100%;
          width: 33%;
        }
        .pt_side_diameter {
          position: relative;
          border-right: 3px solid #D7D9DC;
          height: 100%;
          width: 55%;
        }
        .pt_side_steel_type {
          height: 100%;
          width: 18%;
        }
      }
    }
  }  
  
  div.pt_rings_gama_sec{
    
    display: flex;
    position: relative;
    border-top: 3px solid #878C97;
    
    span.pt_rings{
      width: 50%;
      border-right: 3px solid #D7D9DC;
      p{
        position: relative;
      }
    }
    
    span.pt_gama{
      width: 50%;
      p{
        position: relative;
      }
    }
  }

}

.piles_ticket_piles_data_heb{
  @extend .piles_ticket_piles_data;

  div.piles_ticket_top_sec{
    
    span.pt_total_len{
      border-left: 3px solid #878C97;
      border-right: none;
    }
    
    span.pt_cage_diameters{
      border-left: 3px solid #878C97;
      border-right: none;
    }
  }


  div.piles_ticket_mid_sec {
    display: flex;
    
    span {
      &:first-child {
        border-left: 3px solid #D7D9DC;
        border-right: none;
      }
      
      &:nth-child(2) {
        border-left: 3px solid #878C97;
        border-right: none;
        padding-right: 0px;
        padding-left: 2px;
      }
      
      &:nth-child(3) {
        .pt_side_amount{
          border-left: 3px solid #D7D9DC;
          border-right: none;
        }
        .pt_side_diameter {
          border-left: 3px solid #D7D9DC;
          border-right: none;
        }
      }
    }
  }  
  
  div.pt_rings_gama_sec{

    span.pt_rings{
      border-left: 3px solid #D7D9DC;
      border-right: none;
    }
  }

}

.pt_light_bottom{
  border-bottom: 3px solid #D7D9DC;
}

.pt_bold_text{
  color: #000;
  font-family: Heebo;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
}

.pt_light_text{
  color: #000;
  font-family: Heebo;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
}

.pt_small_text{
  color: #000;
  font-family: Heebo;
  font-size: 10px;
  font-weight: 400;
  line-height: normal;
  top: 2px;
}

.pile_nickname{
  text-align: right;
  position: absolute;
  top: 70px;
  right: 38px;
}

.pt_pr5{
  padding-right: 5px;
}
.pt_pl5{
  padding-left: 5px;
}
.pt_pl2{
  padding-left: 2px;
}
.pt_pr2{
  padding-right: 2px;
}


.sides_diameter_image{
  position: relative !important;
  width: 20px !important;
  margin-right: 3px;
}

.sides_diameter_image_heb{
  @extend .sides_diameter_image;
  margin-right: 0px;
  margin-left: 3px;
}

.ticket_print_count_txt{
  color: #5F6674 !important;
  padding-left: 5px !important;
}