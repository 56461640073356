.updated_policy_popup .modal-dialog {
  max-width: 550px;
}


.policy-container {

  .updated_policy_content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: #EBECEE;
    border-radius: 23px;
    width: 557px;
    padding: 38px 50px;
    
    .policy_text{
      font-family: 'Heebo';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      color: #000;
    }

    .policy_text_2{
      font-family: 'Heebo';
      font-style: normal;
      font-weight: 400;
      font-size: 21px;
      line-height: 30px;
      color: #000;
    }

    .policy_text_changed{
      font-family: 'Heebo';
      font-style: normal;
      font-weight: 700;
      font-size: 21px;
      line-height: 26px;
      color: #000;
    }

    .policy_oscar_text{
      font-family: 'Oscar';
      font-style: normal;
      font-weight: 300;
      font-size: 25px;
      line-height: 35px;
      color: #000;
    }

    .policy_confirmation {
      display: flex;
      align-items: center;
      padding-top: 30px;

      p {
        font-family: 'Heebo';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        color: #000;
      }
    }

    .policy_agree_btn{
      align-items: center;
      padding: 4.5px 19.5px;    
      width: 352px;
      height: 40px;
      border: 1px solid #00B38F;
      border-radius: 20px;
      font-family: 'Heebo';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 31px;
      text-align: center;
      color: #00B38F;

      margin-top: 30px;

      transition: transform 0.3s ease;
    }

    .policy_agree_btn:hover{
      transform: scale(1.1);
    }
  }
}

.terms_link{
  text-decoration: underline;
  text-decoration-color: #000;
  -webkit-text-decoration-color: #000;  
  &:hover{
    text-decoration-color: #000;
    -webkit-text-decoration-color: #000;  
  }
}

.cursor_pointer{
  cursor: pointer;
}

.onetime_popup_background{
  height: 100%;
  width: 100%;
}