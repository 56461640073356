.left {
  position: relative;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  scrollbar-width: none;
}

::-webkit-scrollbar {
  width: 1px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d2d4d6;
}

::-webkit-scrollbar-thumb {
  background: 1px solid #d2d4d6;
}

.sidebar_container {
  width: 280px;
  height: 45px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 2px 28px;
    gap: 10px;

    background: #5f6674;
    border-radius: 5px;

    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 31px;

    color: #ffffff;
    border: none;
  }

  button:focus {
    border: "2px solid #00B38F";
    color: #00b38f;
    background: #fff;
  }

  button.add_btn_error:focus {
    border: "2px solid #CD2017";
    color: #cd2017;
    background: #fff;
  }
}

.sidebar_body {
  padding: 0;
  margin: 0;
  flex-grow: 1;
  overflow: auto;
  overflow-x: hidden;
  scrollbar-width: none;
}

.errors_text {
  padding: 5px 0;
  margin: 0;
}

.nets_sides {
  width: 280px;
  height: 120px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.nets_bg_image {
  position: absolute;
  bottom: 15px;
  width: 240px;
  height: 50px;
  img {
    max-width: 240px;
    max-height: 50px;
  }
}

.net_type {
  border-bottom: 1px solid #d9d9d9;
}

.net_type_heading {
  font-family: "Heebo";
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 31px;
  margin: 0;
  color: #374051;
}

.net_type_select {
  display: flex;
  align-items: center;
  margin: 0;
  &:hover {
    cursor: pointer;
  }
}

.net_type_toggle_left {
  margin: 0;
  width: 69px;
  height: 26px;
  background: #d7d9dc;
  border-radius: 0px 5px 5px 0px;

  font-family: "Heebo";
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #fff;
  user-select: none;

  display: flex;
  justify-content: center;
  align-items: center;
}

.net_type_toggle_right {
  margin: 0;
  width: 69px;
  height: 26px;
  background: #d7d9dc;
  border-radius: 5px 0px 0px 5px;

  font-family: "Heebo";
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #fff;
  user-select: none;

  display: flex;
  justify-content: center;
  align-items: center;
}

.net_type_selected {
  background: #5f6674;
  line-height: 20px;
}

.nets_sidebar_label_large {
  font-family: "Heebo";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #374051;
}
.nets_sidebar_label {
  font-family: "Heebo";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #374051;
}

.nets_units {
  font-family: "Heebo";
  font-style: normal;
  font-weight: 400;
  font-size: 10.5px;
  line-height: 15px;
  align-items: center;
  color: #878c97;
}

input.nets_sidebar_input {
  position: relative;
  width: 120px;
  height: 30px;
  background-color: #fff !important;
  border: 1px solid #afb3b9 !important;
  border-radius: 5px !important;

  font-family: "Heebo";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 13.5px !important;
  line-height: 20px !important;
  text-align: center;
  color: #afb3b9;
  z-index: 8;

  &:focus {
    color: #374051 !important;
  }
}

select.nets_sidebar_input {
  position: relative;
  width: 120px;
  height: 30px;
  background-color: #fff !important;
  border: 1px solid #afb3b9 !important;
  border-radius: 5px !important;

  font-family: "Heebo";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 13.5px !important;
  line-height: 20px !important;
  text-align: center;
  color: #afb3b9 !important;
  z-index: 8;

  background: #ffffff url("../../../../assets/img/nets/arrow_down.svg")
    no-repeat center right;
  background-position: center right 10px;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:focus {
    color: #374051 !important;
  }
}

input.nets_sidebar_input_updated {
  @extend .nets_sidebar_input;
  color: #374051 !important;
}

select.nets_sidebar_input_updated {
  @extend .nets_sidebar_input;
  color: #374051 !important;
}

input.nets_sidebar_input_grey,
select.nets_sidebar_input_grey {
  @extend .nets_sidebar_input;
  background-color: #f5f5f5 !important;
  border: 1px solid #d7d9dc !important;
  color: #afb3b9 !important;
}

input.nets_sidebar_input_error,
select.nets_sidebar_input_error {
  @extend .nets_sidebar_input;
  color: #cd2017 !important;
  border: 2px solid #cd2017 !important;
  &:focus {
    color: #cd2017 !important;
    border: 2px solid #cd2017 !important;
  }
}

input.nets_sidebar_input_updated_error,
select.nets_sidebar_input_updated_error {
  @extend .nets_sidebar_input;
  color: #cd2017 !important;
  border: 2px solid #cd2017 !important;
  &:focus {
    color: #cd2017 !important;
    border: 2px solid #cd2017 !important;
  }
}

input.nets_sidebar_input_warning, input.nets_sidebar_input_updated_warning
select.nets_sidebar_input_warning, select.nets_sidebar_input_updated_warning {
  @extend .nets_sidebar_input;
  color: #e99a00 !important;
  &:focus {
    color: #e99a00 !important;
  }
}
.ns_marginRight {
  margin-right: 23px;
}

.ns_marginLeft {
  margin-left: 23px;
}

.ns_box_mr {
  margin-right: 7px;
}

.ns_box_ml {
  margin-left: 7px;
}

.nets_input_select::-ms-expand {
  display: none;
}

.nets_input_select::before {
  content: "";
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 14.25px;
  height: 7.5px;
  background-color: #afb3b9;
  pointer-events: none;
}

.nets_note {
  display: flex;
  width: 280px;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.nets_sidebar_footer {
  z-index: 10;
  background-color: #EBECEE;
  flex-shrink: 0;
  overflow-x: hidden;
}

.nets_footer_shadow{
  box-shadow: 0px -10px 20px 0px rgba(38, 39, 41, 0.10);
}

.nets_weight {
  position: relative;
}

.nets_weight_total {
  position: absolute;
}

.nets_note_img {
  cursor: pointer;
}

.nets_sides_input {
  display: flex;
  position: absolute;
  bottom: 50px;
}

.nets_error_continer {
  width: 280px;
  height: 25px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
}

.small_txt {
  @extend .nets_units;
  margin-left: 2px;
  margin-bottom: 2px;
  vertical-align: 1.5px;
  color: #374051;
}

.inputX_1 {
  position: relative;
}

.add_button {
  border-top: 1px solid #d9d9d9;
  padding-top: 8px;
  padding-bottom: 8px;
}

.net_sides_fixed_val {
  width: 60px !important;
  height: 30px;
  color: #878c97;
  font-family: "Heebo";
  font-style: normal;
  font-size: 13.5px;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
}

.net_sides_fixed_val_error {
  @extend .net_sides_fixed_val;
  color: #cd2017;
}

.sides_error_X {
  border-bottom: 1px solid #d9d9d9;
  height: 40px;
}

.sides_error_Y {
  border-top: 1px solid #d9d9d9;
  height: 40px;
}
