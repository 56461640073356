.nets_shape_val {
  width: 50px !important;
  text-align: center;
  font-size: 15px !important;
  background-color: #fff;
}

.nets_shape_val_error {
  @extend .nets_shape_val;
  color: #cd2017 !important;
}
