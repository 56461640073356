.custom__popup__modal__left{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 18px;
    width: 41rem;
    height: 465px;
    margin-left: -24%;
  }

.custom__popup__modal__right{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 18px;
    width: 41rem;
    height: 470px;
    margin-left: -24%;
  }


.popup__text{
    font-family: 'Heebo';
    font-weight: 400;
    font-size: 21px;
    line-height: 30.84px;
    text-align: center;
    margin-top: 2rem;
    
   
}

.bid__icon{
    width: 52%;
    text-align: center;
}

.bid__icon img{
    width: 67%;
}



.bid_txt_left{
    font-family: 'Oscar';
    font-weight: 400;
    font-size: 30px;
    line-height: 44.06px;
    margin-top: -5%;
}

.bid_txt_right{
    font-family: 'Heebo';
    font-weight: 400;
    font-size: 30px;
    line-height: 44.06px;
    margin-top: -5%;
}

.accept_txt_right {
    font-family: 'Heebo';
    font-weight: 400;
    font-size: 30px;
    line-height: 44.06px;
    text-align: center;
    width: 65%;
    margin: 0.5rem auto;
    line-height: 47px;
    
}
.accept_txt_left {
    font-family: 'Oscar';
    font-weight: 300;
    font-size: 36px;
    line-height: 44.06px;
    text-align: center;
    width: 70%;
    margin: 0.5rem auto;
    line-height: 41.36px;
    
}

.bid_btns_right{
    margin-top: 1.7rem;
}

.bid_btns_left{
    margin-top: 1.7rem;
}


.get__bid__right button, .bid__cancel__btn_right button{
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 30.84px;
    border-radius: 23px;
    border: 1px solid;
    padding: 0.3rem 1rem;
}

.get__bid__left button, .bid__cancel__btn_left button{
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 30.84px;
    border-radius: 23px;
    border: 1px solid;
    padding: 0.3rem 1.2rem;
}



.get__bid__right button{
    color: #00B38F;
    

}

.get__bid__right button:hover{
    background-color: white;
}

.thankBtn button{
    padding-left: 1.2rem;
    padding-right: 1.2rem;
}


.get__bid__left button{
    color: #00B38F;
        

}

.get__bid__left button:hover{
    background-color: white;
        

}

.bid__cancel__btn_right button{
    color: #AFB3B9;
    margin-right: 12px;
    
}

.bid__cancel__btn_right button:hover{
    background-color: white;

}

.bid__cancel__btn_left button{
    color: #AFB3B9;
    margin-left: 12px;
    
}

.bid__cancel__btn_left button:hover{
    background-color: white;

}

.notes{
    display: flex;
    justify-content: center;
    
}

.notes textarea{
    resize: none;
    border: none;
    outline: none;
    padding: 2rem;
    height: 260px;
    width: 502px;
    margin-top: 1.3rem;
    text-align: center;
    font-size: 18px;
    
    
}

.notes ::placeholder{
    font-family: 'Heebo';
    font-size: 18px;
    font-weight: 400;
    line-height: 26.44px;
    text-align: center;
    color: #AFB3B9;
}