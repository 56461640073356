.background-t{
    background-image: url("../../../assets/img/ticket__create/ticket_background.png");
    background-size: cover;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.ticket-creator_text{
    font-family: 'Oscar v2 FM';
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 41px;
    display: flex;
    align-items: center;
}

.ticket-creator_text_heebo{
    font-family: 'Heebo';
    font-style: normal;
    font-size: 36px;
    line-height: 41px;
    display: flex;
    align-items: center;
}

.ticket_text{
    display: inline;
}
.label-orderno{
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 31px;
    display: flex;
    align-items: center;
    text-align: right;

    color: #000000;
}

.underlined{
    font-size: 1rem;
    display: inline-block;
    color: #00B38F;

    & :hover{
        font-size: 1rem;
        display: inline-block;
        color: #00B38F;
    }
}

.whatsapp-link{
    padding-right: 3px;
}
.creator_form{
    display: flex;
    justify-content: center;
    align-items: center;
    button {
        all: unset;
        cursor: pointer;
    }
}

.highlighted_txt{
    font-size: 1rem;
    display: inline-block;
    background-color: #00B38F;
    color: white;
    border-radius: 5px;
}

.highlighted_txt_blue{
    font-size: 1rem;
    display: inline-block;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #374051;
    color: white;
    border-radius: 5px;
}

.ticket_status{
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
}

.ticket_create_order_input{
    background: #FFFFFF;
    border: 2px solid #00B38F;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
}

.input_order_no{
    padding: 0!important;

    .order_no_input {
        background-color: #FFFFFF !important;
        border-radius: 20px !important;
        padding: 7px 18px;
        width: 375px;

        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        border: 2px solid #000000 !important;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

        &:focus {
            border: 2px solid #00B38F !important;
        }

        &::placeholder {
            color: #878C97;
        }
    }

    .submit_order {
        margin: 0px 11px;
        cursor: pointer;
    }
}

.order_no_input_warning {
    background-color: #FFFFFF !important;
    border-radius: 20px !important;
    padding: 7px 18px;
    width: 375px;

    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    border: 2px solid #000000 !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: 2px solid #CD2017 !important;


    &::placeholder {
        color: #878C97;
    }
}

.info_icon{
    margin: 0px 8px;
    cursor: pointer;
}

.creator_info_text{
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;

    color: #000000;
}

.order_no_info_tooltip{
    background: #5F6674;
    border-radius: 10px;
}

.info_tip_text{
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #FFFFFF;
}

.info_hover_tip_details [class$="inner"]{
    background: #5F6674;
    border-radius: 10px;
}

.info_hover_tip_details [class$="arrow"]{
    background: #5F6674;
    border-radius: 10px;
}

.orderNo_detials_text{
    padding-top: 12px;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
}

.error-textt{
    font-family: 'Heebo';
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    align-items: center;
    color: #CD2017;
}

.error_linkk{
    text-decoration: underline;
    color: #CD2017;
    &:hover {
        color: #CD2017;
    }
}

.error_404_container{
    position: absolute;
    top: 110px;
}
