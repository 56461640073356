.sketch-body {
  width: 912px;
  background: #d9d9d9;
  padding: 25px;

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 10.5px;
    line-height: 15px;

    padding-bottom: 15px;
  }

  .refresh_button{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid #5F6674 !important;
    padding: 0px 8px;
    margin-bottom: 3px;
    position: relative;
    top: -8.5px;

    .reload_font {
      font-family: Heebo;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: #5F6674;
    }
  }

  .sketch {
    width: 867.95px;
    height: 566.53px;
    background: #FFFFFF;

    .high__img {
        height: 566.53px;
    }
  }
}
