.send_supplier_email_popup .modal-dialog {
  max-width: 550px;
}

.send_email_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.send_email_content {
  text-align: center;
  background: #ebecee;
  border-radius: 23px;
  width: 557px;
}

.no_easybar_user_content {
  text-align: center;
  background: #ebecee;
  border: 2px solid #e68f8b;
  border-radius: 23px;
  width: 557px;
}

.send_email_text {
  font-family: "Heebo";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26.4px;
  color: #000;

  padding: 37px 45px 0px;
}

.no_user_text {
  @extend .send_email_text;
  padding: 37px 80px 0px;
}

.send_email_button {
  cursor: pointer;
  min-width: 112px;
  height: 40px;
  background: #ebecee;
  border-radius: 20px;
  border: 1px solid #00b38f;
  color: 000;
  margin: 30px 0 37px;

  font-family: "Heebo";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 31px;
  color: #00b38f;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 5px !important;
  margin-right: 5px !important;
  &:hover {
    background: #fff;
  }
}

.send_email_input {
  background: #ffffff !important;
  border-radius: 20px !important;
  border: none !important;
  padding: 7px 18px;
  width: 338px;
  height: 40px;
  margin: 57px 106px 20px !important;

  font-family: "Heebo";
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 31px;
  text-align: center;

  &::placeholder {
    font-family: "Heebo";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    display: flex;
    align-items: center;
    text-align: center;
    color: #afb3b9;
  }
}

.send_email_input_list_open {
  @extend .send_email_input;
  border-radius: 20px 20px 0 0 !important;
}

.send_email_input_empty {
  @extend .send_email_input;
  border: 2px solid #cd2017 !important;
}
.send_email_input_empty_list_open {
  @extend .send_email_input;
  border: 2px solid #cd2017 !important;
  border-radius: 20px 20px 0 0 !important;
}

.email_input_container {
  position: relative;
}

.recent_email_list {
  position: absolute;
  top: 97px;
  left: 109px;
}

.recent_email_option {
  cursor: pointer;

  width: 339px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #d7d9dc;

  font-family: "Heebo";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: #afb3b9;

  background-color: #ffffff;

  &:hover {
    background-color: #f5f5f5;
    color: #374051;
  }
}

.order_share_popup_buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}
